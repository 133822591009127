


import React, { Component } from 'react'
import {

    getBranchesLatest
} from './../../store/actions';
import { Toolbar } from 'primereact/toolbar';
import { BreadCrumb } from 'primereact/breadcrumb';
import Navbar from './../navbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import SvgViewer from './../customComponents/svgViewer';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import SearchBar from './searchBar';
import Service from '../../services';
import { baseUrlAdmin } from '../../store/apiConstants';
import LoadingComponent from '../loadingComponent';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.service = new Service();
    }

    getSectionsData = (nodes, key, totalLevels) => {
        let data = _.cloneDeep(nodes)
        let sectionsData = {};
        let selectedKey = key;
        for (let k = totalLevels; k > 1; k--) {
            for (let j = 0; j < data.length; j++) {
                const i = data[j];
                if (i.children) {
                    if (i.children.includes(selectedKey)) {
                        sectionsData['level' + k] = i.children;
                        sectionsData = {
                            ...sectionsData,
                            ['level' + k]: i.children.map(c => data.find(d => d.key == c)),
                            ['levelSelected' + k]: selectedKey
                        }
                        selectedKey = _.cloneDeep(i.key)
                        break;
                    }
                }
            }
        }
        sectionsData = {
            ...sectionsData,
            ['level1']: data.filter(nod => nod.level == 1),
            ['levelSelected1']: selectedKey
        }
        return sectionsData
    }

    componentDidMount() {

        if (!this.props.branchData) {
            this.props.getBranchesLatest();
        } else {
            if (this.props.selectedUser && this.props.branchData) {
                let finaldata = this.getSectionsData(this.props.branchData, this.props.selectedUser.sectionId, this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata
                });

            }
        }
    }



    componentDidUpdate(prevProps) {
        if (prevProps.branchData !== this.props.branchData) {
            if (this.props.editUser && this.props.branchData) {
                let finaldata = this.getSectionsData(this.props.branchData, this.props.editUser.sectionId, this.props.branchLevels.length);
                this.setState({
                    sectionsData: finaldata
                });
            }
        }



    }


    render() {
        const { selectedUser, isShowUserInfo } = this.props;

        // console.log(this.state)
        return (
            <>
                <Dialog header="User Info"
                    blockScroll={true}
                    draggable={false} footer={() => {
                        return (<div>
                            <Button label="Close" className='p-button-outlined' onClick={this.props.onHide} />
                        </div>)
                    }}
                    className="user-info-d"
                    closeOnEscape={true}
                    dismissableMask={true}
                    visible={isShowUserInfo}
                    closable={false}
                    onHide={this.props.onHide}>
                    {selectedUser && <div className='user-info'>
                        <div className="p-grid ">
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <p className='text-label2'>Name</p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                <InputText value={selectedUser.given_name} className='p-inputtext-style1 ma-w100p' />
                            </div>
                        </div>
                        <div className="p-grid ">
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <p className='text-label2'>Phone</p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                <InputText value={selectedUser.phone_number} className='p-inputtext-style1 ma-w100p' />
                            </div>
                        </div>
                        <div className="p-grid ">
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <p className='text-label2'>Email ID</p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                <InputText value={selectedUser.email} className='p-inputtext-style1 ma-w100p' />
                            </div>
                        </div>
                        <div className="p-grid ">
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <p className='text-label2'>Board</p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                <InputText value={selectedUser['custom:board']} className='p-inputtext-style1 ma-w100p' />
                            </div>
                        </div>
                        <div className="p-grid ">
                            <div className="p-col-4 p-md-4 p-lg-4">
                                <p className='text-label2'>Class</p>
                            </div>
                            <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                <InputText value={selectedUser['custom:grade']} className='p-inputtext-style1 ma-w100p ' />
                            </div>
                        </div>
                        {
                            this.props.branchLevels ? this.props.branchLevels.map((level, index) => {
                                return <>
                                    <div className="p-grid ">
                                        <div className="p-col-4 p-md-4 p-lg-4">
                                            <p className='text-label2'>{level.levelName}</p>
                                        </div>
                                        <div className="p-col-8 p-md-8 p-lg-8 ma-mt2">
                                            <Dropdown

                                                value={(this.state.sectionsData && this.state.sectionsData['levelSelected' + (index + 1)]) ? this.state.sectionsData['levelSelected' + (index + 1)] : ''}
                                                className='ma-w100p'
                                                optionLabel={"name"}
                                                optionValue={"key"}
                                                options={(this.state.sectionsData && this.state.sectionsData['level' + (index + 1)]) ? this.state.sectionsData['level' + (index + 1)] : []}
                                                disabled
                                                placeholder={`No data`} />
                                        </div>
                                    </div>
                                </>
                            }) : null
                        }
                    </div>}
                </Dialog >
                {
                    this.props.isBranchLoading && <>
                        <LoadingComponent />
                    </>
                }
            </>
        )
    }
}



const mapStateToProps = (state) => ({
    isBranchLoading: state.branchDataLatest.isLoading,
    branchLevels: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.levels,
    branchData: state.branchDataLatest && state.branchDataLatest.data && state.branchDataLatest.data.data,
});


export default connect(mapStateToProps, {

    getBranchesLatest
})((withRouter(UserInfo)));