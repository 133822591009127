import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import React, { Component } from 'react'
import Service from '../../../../services';
import { baseUrlAdmin } from '../../../../store/apiConstants';
import { BasicLazyParams } from '../../../../utile';
import LoadingComponent from '../../../loadingComponent';
import ExamCorrection from './examCorrection';
import StudentChallenges from './studentChallenges';

export default class SubjectiveEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChallenges: false,
            globalSearch: '',
            // totalRecords: 18,
            lazyParams: BasicLazyParams,
            columns: [{ columnName: 'User Name', isVisible: true, id: 'user_name' }],
            totalRecords: 0,
            showSearch: false,
            examResults: [],
            isShowCorrectionScreen: false
        }

        this.service = new Service();

    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.onSearch);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {


        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }

    onSearch = () => {
        const {
            lazyParams, globalSearch
        } = this.state;

        let { assessExamId } = this.props;
        let filters = { isChallenged: { $ne: true } };
        this.setState({
            isLoading: true,
            examResults: [],
            totalRecords: 0
        });
        this.service.post(`${baseUrlAdmin}/results/getexamresults/${assessExamId}?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`, filters, true).then((data) => {
            

            if (data && data.status && data?.res?.data) {
                this.setState({
                    totalRecords: data.res.totalRecords,
                    examResults: data.res.data,
                    isLoading: false
                })
            } else {
                this.setState({
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'No Exams', detail: "No Exams Found", life: 3000 });
            }


        }).catch(e => {
            this.setState({
                isLoading: false
            })

            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            console.log(e);

        });


    }
    componentDidMount() {
        this.onSearch();

    }

    onCorrectionDone = () => {

        this.setState({
            isShowCorrectionScreen: false,
            examResultForCorrection: null
        }, () => {
            this.onSearch();
        });

        this.toast.show({ severity: 'success', summary: 'Success', detail: "Exam Correction Record saved Successfull.y", life: 3000 });

    }


    startCorrection = (examResult) => {
        this.setState({
            isShowCorrectionScreen: true,
            examResultForCorrection: examResult
        })
    }

    render() {
   //    console.log(this.props.assessExamId, 'examid')
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Users List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>

                <div className="p-input-icon-left ma-tbl-filter">
                    <ul className="ma-tbl-li">
                        {/* <Authorizer permId={PERMISSIONS.ORG_SEARCH} > */}
                        <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
                        <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
                        {/* </Authorizer> */}
                        {/* <li><i data-pr-tooltip="Filter" data-pr-position="bottom" className="pi pi-filter ma-tbl-icon ma-tbl-fi"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-fi" /> */}
                        <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
                        {/* <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} >
                            
                        </Authorizer> */}
                        {/* <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} > */}
                        <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
                        <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
                        {/* </Authorizer> */}
                    </ul>
                </div>
                <Menu
                    className='table-filter-w'
                    model={[
                        {
                            template: (<div className='table-filter'>
                                <h4 className="filter-title">View Columns</h4>
                                <ul>
                                    {this.state.columns.map((column) => {
                                        return <li key={column.id} onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
                                            checked={column.isVisible}
                                        ></Checkbox>
                                            <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
                                        </li>
                                    })}
                                </ul>
                            </div>)
                        }]
                    }
                    popup
                    ref={el => this.menu = el} />
            </div>
        );
        return (
            <>
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-text ma-bg-white" aria-label="Back" onClick={this.props.onBackClick} />
                <div>
                    <div className='ma-mt20'>
                        <Button label="Users List" onClick={() => {
                            this.setState({
                                isChallenges: false
                            })
                        }} className={`${this.state.isChallenges === false ? '' : 'p-button-outlined'}`} />
                        <Button label="Student Challenges" onClick={() => {
                            this.setState({
                                isChallenges: true
                            })
                        }} className={`${this.state.isChallenges === true ? '' : 'p-button-outlined'} ma-ml15`} />
                    </div>

                </div>

                {this.state.isChallenges ? <>
                    <StudentChallenges  assessExamId={this.props.assessExamId} />


                </> : <>
                    {
                        this.state.isShowCorrectionScreen ? <>
                            <ExamCorrection examResultForCorrection={this.state.examResultForCorrection} onCancel={() => {
                                this.setState({
                                    isShowCorrectionScreen: false,
                                    examResultForCorrection: null
                                })

                            }}
                                onCorrectionDone={this.onCorrectionDone}
                            />
                        </> : <div className="card datatable-crud-demo ma-mt20">
                            <DataTable ref={(el) => this.dt = el}
                                scrollable scrollHeight="500px"
                                responsiveLayout="scroll"
                                dataKey="_id"
                                value={this.state.examResults}
                                filters={this.state.lazyParams.filters}
                                paginator
                                lazy
                                onPage={this.onPage}
                                onSort={this.onSort}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                sortField={this.state.lazyParams.sortField}
                                sortOrder={this.state.lazyParams.sortOrder}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                paginatorLeft={() => {
                                    return <>
                                        <p className='avail-q'>Available results:  <span>{this.state.totalRecords}</span> </p>
                                    </>
                                }}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                header={header}>
                                {this.isColumnVisible('user_name') && <Column field="user_name" header="User Name" />}
                                <Column field="examStatus" header="Exam Status" />
                                <Column header="Correction" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        {
                                            rowData.examStatus == 'SUBMITTED' ? <Button label="Start" onClick={() => { this.startCorrection(rowData) }} /> : <Button disabled label='Completed' className='p-button-secondary' />
                                        }
                                    </>
                                    );
                                }} ></Column>
                            </DataTable>
                            <Toast ref={(el) => this.toast = el} position="bottom-right" />
                        </div >
                    }

                </>}

                {
                    this.state.isLoading && <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}
