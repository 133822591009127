import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getBoardsData,
} from '../../../store/actions';
import { baseUrlAdmin } from '../../../store/apiConstants';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../../services';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LoadingComponent from '../../loadingComponent';
import { Toast } from 'primereact/toast';


class AssignQuestionToTopic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            boardId: null,
            classId: null,
            groupId: null,
            classes: [],
            boards: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            boardName: null,
            className: null,
            groupName: null,
            subjectName: null,
            chapterName: null,
            topicName: null,
            selectedGrades: [],

            isLoading: false,
            isShowAssignCountDialog: false,
            assignedCount: null
        }


        this.service = new Service();
    }





    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }



    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ className: item.className, classId: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,

        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass.className,
                groupId: _groups[0].value,
                groupName: _groups[0].label,
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,

                content: null,

            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start
    getSelectedCurriculum = (subjects) => {
        let { subjectId, chapterId, topicId } = this.props.editQuestion;

        let selectedSubject = subjects.find((subject) => subject.subjectId == subjectId);
        let selectedChapter = selectedSubject && selectedSubject.chapters && selectedSubject.chapters.find(c => c.chapterId == chapterId);
        let selectedTopic = selectedChapter && selectedChapter.topics && selectedChapter.topics.find(c => c.topicId == topicId);

        return {
            subjects,
            chapters: selectedSubject.chapters,
            topics: (selectedChapter && selectedChapter.topics) ? selectedChapter.topics : [],
            subjectId,
            chapterId,
            topicId,
            subjectName: selectedSubject.subjectName,
            chapterName: selectedChapter ? selectedChapter.chapterName : null,
            topicName: selectedTopic ? selectedTopic.topicName : null,
            content: null,

        };

    }

    getCurriculumData = (isEdit) => {
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {



                if (data && data.res && data.res.Item) {
                    let selected = isEdit ? this.getSelectedCurriculum(data.res.Item.subjects) : {}

                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false,

                        ...selected
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                subjectName: selectedSubject[0].subjectName,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,

            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,

            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName
            });
    };

    addSelectedGrade = () => {

        let { boardId, classId, groupId, subjectId, chapterId, topicId,
            boardName, className, groupName, subjectName, chapterName, topicName } = this.state;
        let selectedGrades = this.state.selectedGrades;
        let records = selectedGrades.filter(grade => grade.topicId == topicId)
        if (records && records.length) {
            this.setState({
                snackBarMsg: 'Already added',
                snackBarOpen: true
            });
        } else {

            selectedGrades.push({
                boardId, classId, groupId, subjectId, chapterId, topicId,
                boardName, className, groupName, subjectName, chapterName, topicName
            });
            this.setState({
                selectedGrades
            });
        }

    };

    deleteGrade = (topicId) => {
        let selectedGrades = this.state.selectedGrades.filter(grade => grade.topicId != topicId);
        this.setState({
            selectedGrades
        });
    }

    assignQuestionsToTopic = () => {

        //  console.log(this.state.selectedGrades);


        if (this.state.selectedGrades && this.state.selectedGrades.length) {

            this.setState({
                isLoading: true
            });


            const service = new Service();
            let url = `${baseUrlAdmin}/question/assignquestionstotopics`;

            service.post(url, { questionIds: this.props.selectedQuestions.map(q => { return { _id: q._id, questionId: q.questionId, questionTarget: q.questionTarget, questionType: q.questionType } }), curriculums: this.state.selectedGrades }, true).then((res) => {
                if (res.status && res.res.status == 200) {
                    this.setState({
                        isLoading: false,
                        assignedCount: res.res.data,
                        isShowAssignCountDialog: true
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        
                    })
                    this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Some error occured', life: 3000 });
                }
            });
        } else {
            this.setState({
                isLoading: false,
                
            })
            this.toast.show({ severity: 'error', summary: 'Warning', detail: 'Select atleast one curriculum', life: 3000 });
        }

    }

    removeTemplate = (rowData) => {
        return (<><span data-pr-tooltip="Delete Curriculum" data-pr-position="bottom" className={`remove${rowData.topicId.substr(0, 5)}`} onClick={() => { this.deleteGrade(rowData.topicId) }}>
            <i className='pi pi-trash'> </i>
        </span>
            <Tooltip className="table-li-tooltip2" autoHide={false} target={`.remove${rowData.topicId.substr(0, 5)}`} />
        </>);
    }


    onConfirmOk = () => {
        this.setState({
            isShowAssignCountDialog: false,
            assignedCount: null
        }, this.props.onSucessfullyAssigned)
    }



    render() {
        // const header = (
        //     <div className="table-header ma-tbl-header">
        //         <div className="ma-tbl-left">
        //             <h2 className='ma-table-title'>Selected List</h2>
        //             {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

        //         </div>

        //         <div className="p-input-icon-left ma-tbl-filter">
        //             <ul className="ma-tbl-li">
        //                 <Authorizer permId={PERMISSIONS.ORG_SEARCH} >
        //                     <li><i data-pr-tooltip="Search" data-pr-position="bottom" className="pi pi-search ma-tbl-icon ma-tbl-srch" onClick={(e) => { this.setState({ showSearch: !this.state.showSearch }) }}></i></li>
        //                     <Tooltip className="table-li-tooltip" autoHide={false} target=".ma-tbl-srch" />
        //                 </Authorizer>

        //                 <Authorizer permId={PERMISSIONS.ORG_VIEW_COLUMNS} >
        //                     <li><i onClick={(event) => this.menu.toggle(event)} data-pr-tooltip="View Columns" data-pr-position="bottom" className="pi pi-sort-alt ma-tbl-icon ma-tbl-sort"></i></li>
        //                     <Tooltip className="table-li-tooltip" target=".ma-tbl-sort" />
        //                 </Authorizer>
        //                 <Authorizer permId={PERMISSIONS.ORG_DOWNLOAD} >
        //                     <li><i data-pr-tooltip="Download" data-pr-position="bottom" className="pi pi-download ma-tbl-icon ma-tbl-dwnld" onClick={this.exportCSV}></i></li>
        //                     <Tooltip className="table-li-tooltip" target=".ma-tbl-dwnld" />
        //                 </Authorizer>
        //             </ul>
        //         </div>
        //         <Menu
        //             className='table-filter-w'
        //             model={[
        //                 {
        //                     template: (<div className='table-filter'>
        //                         <h4 className="filter-title">View Columns</h4>
        //                         <ul>
        //                             {this.state.columns.map((column) => {
        //                                 return <li onClick={() => this.onColumnClick(column)}><Checkbox inputId={column.id}
        //                                     checked={column.isVisible}
        //                                 ></Checkbox>
        //                                     <label htmlFor={column.id} className="p-checkbox-label p-name">{column.columnName}</label>
        //                                 </li>
        //                             })}
        //                         </ul>
        //                     </div>)
        //                 }]
        //             }
        //             popup
        //             ref={el => this.menu = el} />
        //     </div>
        // );
        return <>
            <Dialog
                visible={true}
                onHide={this.props.onCloseDialog}
                style={{ width: '96%', height: '96vh' }}
                header="Select Curriculums"
                modal
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Cancel" className='p-button-outlined' onClick={this.props.onCloseDialog} />
                        <Button label={`Assign`} className='ma-m-lr10' onClick={this.assignQuestionsToTopic} />

                    </div>)
                }}
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                dismissableMask={false}
                closable={true}
            >
                <Dropdown value={this.state.boardId}
                    className='ma-w200 ma-mr20 ma-mt10'
                    options={this.props.boards}
                    optionLabel="boardName"
                    optionValue="boardId"
                    onChange={(e) => this.onChangeBoard(e.value)}
                    placeholder="Select Board" />
                <Dropdown value={this.state.classId}
                    options={this.state.classes}
                    optionLabel='className'
                    optionValue='classId'
                    className='ma-mr20 ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                <Dropdown value={this.state.subjectId}
                    options={this.state.subjects}
                    optionLabel='subjectName'
                    optionValue='subjectId'
                    className='ma-mr20 ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                <Dropdown value={this.state.chapterId}
                    options={this.state.chapters}
                    optionLabel='chapterName'
                    optionValue='chapterId'
                    className='ma-mr20 ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                <Dropdown value={this.state.topicId}
                    options={this.state.topics}
                    optionLabel='topicName'
                    optionValue='topicId'
                    className='ma-mr20 ma-w200 ma-mt10'
                    onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />
                {(this.state.topicId && this.state.topicId != '') ? (
                    <Button
                        label='Add'
                        onClick={() => this.addSelectedGrade()}
                        className='ma-mt10'
                    >
                    </Button>
                ) : null}

                <div className="card datatable-crud-demo ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        value={this.state.selectedGrades}
                        dataKey="topicId"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={this.state.globalFilter}
                    //header={header}
                    >
                        <Column field="boardName" header="Board Name" />
                        <Column field="className" header="Class Name" />
                        <Column field="subjectName" header="Subject Name" />
                        <Column field="chapterName" header="Chapter Name" />
                        <Column field="topicName" header="Topic Name" />
                        <Column header="Remove" body={this.removeTemplate} ></Column>
                    </DataTable>
                </div>

                {(this.state.isLoading || this.props.isLoading) &&
                    <LoadingComponent />
                }
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </Dialog>

            <Dialog
                header={<>Status</>}
                draggable={false}
                className='ma-alert-box'
                blockScroll={true}
                closable={false}
                visible={this.state.isShowAssignCountDialog}
                style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="Ok" className='ma-m-lr10' onClick={this.onConfirmOk} />
                    </div>)
                }}
                onHide={this.onConfirmOk}>
                <p className='ma-alert-msg p-button-success'>
                    <b> {this.state.assignedCount && this.state.assignedCount.added}</b> questions assigned to selected hierarchy</p>
                <p className='ma-alert-msg p-button-success'> <b> {this.state.assignedCount && this.state.assignedCount.notAdded}</b>
                    {' '} questions not assigned to selected hierarchy due to duplicate</p>
            </Dialog>
        </>
    }



}

const mapsStatesToProps = (state) => {
    return {
        boards: userAssignedBoards(state, 'activeBoards'),
        isLoading: state.boardsData.loading,
    };
};

export default connect(mapsStatesToProps, {
    getBoardsData,
})(withRouter(AssignQuestionToTopic));
