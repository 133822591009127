import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog'
import { connect } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import Authentication from './../../session';
import { withRouter } from 'react-router-dom';
import { userAssignedBoards } from '../../../store/selectors/userAssignedBoards';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import { FileTypes, warningDailogInit } from '../../../utile';
import {
    getBoardsData
} from './../../../store/actions';
import { Toast } from 'primereact/toast';
import _ from 'lodash';

class AssignDiagnosticTestToContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,

            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            boardId: null,
            classId: null,
            groupId: null,
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: null,
            warningDialog: warningDailogInit,
            globalFilter: '',
            examToAssign: this.props.examToAssign
            //contests[3],

        }
        this.service = new Service();
    }




    componentDidMount() {
        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }
    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            isBaseBoard: selectedBoard.isBase,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,
                filteredContentDetails: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: []
            });
    };

   
  

  



    onAssign = (content) => {
        if (!this.state.chapterId) {
            return this.toast.show({ severity: 'error', summary: 'Please select heirarchy till chapter', detail: ' ', life: 3000 });
        }

        let payload = {
            examId: this.props.examToAssign.examId,
            chapterId: this.state.chapterId
        }
        console.log(payload, 'paylo')
        this.setState({
            isLoading: true
        });

        const url = `${baseUrlAdmin}/examination/assignDignoseExam`;
        this.service.post(url, payload, true).then((data) => {
            if (data && data.res && data.res.success) {
                this.setState({
                    isLoading: false,
                }, this.props.onAssignSuccess);
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: data.errMessage, life: 3000 });
            }
        }).catch(e => {
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    render() {
        //   console.log('examToAssign', this.state.examToAssign);
        const { examToAssign } = this.state;
     


        return (<><Dialog
            //visible={this.state.isShowCreate}
            style={{ width: '90%', height: '90vh' }}
            //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
            modal
            blockScroll={true}
            //footer={this.createRoleDialogFooter}
            draggable={false}
            // footer={this.footer}
            // header={null}
            closeOnEscape={false}

            dismissableMask={false}
            className='add-a-resource-dialog'
            visible={true}
            closable={true}
            header="Assign Diagnostic Test to Chapter"
            onHide={this.props.onCancel}>
            <div className='assign-quiz' >



                <div className='p-grid ma-no-pm'>
                    <div className='p-col-12 p-md-6 paperd-s1 exam-preview'>
                        <div className=' ma-m20'>
                            <div>
                                <p className='e-label-2'>Quiz Name</p>
                                <p className='exam-name'>{examToAssign.examName}</p>
                            </div>
                            <div className='p-grid exam-info'>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Number Of Questions</p>
                                        <p className='e-value'>{examToAssign.noOfQuestions}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Total Time</p>
                                        <p className='e-value'>{examToAssign.totalTime} Min</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Total Marks</p>
                                        <p className='e-value'>{examToAssign.totalMarks}</p>
                                    </div>
                                </div>
                                <div className='p-col-12 p-md-3'>
                                    <div className=' '>
                                        <p className='e-label'>Question Types</p>
                                        <p className='e-value'>{(examToAssign.questionType && examToAssign.questionType.length) ? examToAssign.questionType.join(', ') : 'All'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <Dropdown value={this.state.boardId}
                        className='ma-m-r10 ma-w200'
                        //options={this.state.boards}
                        options={this.props.boards}
                        optionLabel="boardName"
                        optionValue="boardId"
                        onChange={(e) => this.onChangeBoard(e.value)}
                        placeholder="Select Board" />
                    <Dropdown value={this.state.classId}
                        options={this.state.classes}
                        className='ma-m-lr10 ma-mt10 ma-w200'
                        onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                    <Dropdown value={this.state.subjectId}
                        options={this.state.subjects}
                        optionLabel='subjectName'
                        optionValue='subjectId'
                        className='ma-m-lr10 ma-mt10 ma-w200'
                        onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                    <Dropdown value={this.state.chapterId}
                        options={this.state.chapters}
                        optionLabel='chapterName'
                        optionValue='chapterId'
                        className='ma-m-lr10 ma-mt10 ma-w200'
                        onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                </div>

                <div className='quiz-info-assign'>




                    {/* <p>Number of Questions</p>
                    <p> {examToAssign.noOfQuestions}</p>

                    <p>Total Time</p>
                    <p> {examToAssign.totalTime}</p>

                    <p>Total Marks</p>
                    <p> {examToAssign.totalMarks}</p> */}






                    <Button disabled={!(this.state.chapterId)} className='ma-mt20' label='Assign' onClick={this.onAssign} />

                </div>





            </div>
        </Dialog>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}




const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,

});


export default connect(mapStateToProps, { getBoardsData })(Authentication(withRouter(AssignDiagnosticTestToContent)));