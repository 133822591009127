import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { withRouter } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authentication from './../session';
import _ from 'lodash';

import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import Service from './../../services';

import {
    getBoardsData
} from './../../store/actions';
import { RadioButton } from 'primereact/radiobutton';
import LoadingComponent from '../loadingComponent';
import { baseUrlAdmin } from '../../store/apiConstants';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import ContentPlayer from '../contentMaster/contentPlayers';




class PeriodResources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            boardId: null,
            classId: null,
            groupId: null,
            boards: [],
            classes: [],
            groups: [],
            subjects: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            content: null,
            isShowAddDialog: false,
            resourseOrigin: 'upload',
            globalFilter: '',
            selectedTopicResourceTypes: this.props.selectedTopicResourceTypes

        }
        this.service = new Service();
    }



    componentDidMount() {

        //  this.props.getBoardsData();
        this.getContentsByChapterId();


    }

    getContentsByChapterId = () => {
        const { chapterId } = this.props;

        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/content/getcontent/${chapterId}`;
        this.service.get(url, true).then((res) => {
            

            if (res && res.res && res.res.Item) {
                let assignedContents = this.checkIsAlreadyAssigned(res.res.Item);
                // let filteredContentDetails = [];
                // if (res.res.Item.contentDetails) {
                //     filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                // }
                this.setState((prevState) => {
                    return {
                        content: res.res.Item,
                        isLoading: false,
                        selectedContent: assignedContents,
                        filteredContentDetails: res.res.Item
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }


        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.boards !== this.props.boards) {
        //     if (this.props.boards && this.props.boards.length) {
        //         const boardId = this.props.boards[0].boardId;
        //         const _classes = [];
        //         const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        //         if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
        //             selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        //         }
        //         this.setState({
        //             classes: _classes,
        //             boardId,
        //         });
        //     }
        // }


    }

    // grades selection --- start
    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], classId: null,
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
        });

    };

    onChangeClass = (classId) => {
        this.setState({
            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                groupId: _groups[0].value
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,
                content: null,
                filteredContentDetails: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })

            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, false).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
        } else {
            this.setState({
                isLoading: false
            })
        }
    };


    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,
                filteredContentDetails: []

            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                topicId: null,
                content: null,
                filteredContentDetails: []
            });
    };

    onChangeTopic = (topicId) => {
        this.setState(
            {
                topicId,
                content: null
            }, () => {
                this.getContentDetails();
            });
    };

    checkIsAlreadyAssigned = (contentDetails) => {
        let assignedContents = [];
        if (this.props.contentInfo && this.props.contentInfo.length) {
            this.props.contentInfo.forEach((con) => {
                let inde1;
                let assignedCont = contentDetails.find((a, index) => {
                    if (a.contentId == con.contentId) {
                        inde1 = index
                        return a;
                    }
                });
                if (inde1) {
                    contentDetails[inde1].localName = con.contentName;
                }

                if (assignedCont) {
                    assignedContents.push(con);
                }
            });
        }
        return assignedContents;
    }


    filterResource = (contents, selectedTopicResourceTypes) => {
        let filter = selectedTopicResourceTypes.find((a) => a.isSelected);
        if (filter && filter.value) {
            contents = contents.filter((c) => c.contentFileType == filter.value)
            return contents.sort((a, b) => parseInt(a.priority) - parseInt(b.priority))
        } else {
            return []
        }
    }

    filterBySelected = (contents) => {
        return this.filterResource(contents, this.state.selectedTopicResourceTypes);
    }



    getContentDetails = () => {
        const { boardId, classId, groupId, subjectId, chapterId, topicId } = this.state;

        this.setState({
            isLoading: true
        });
        const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/subject/${subjectId}/chapter/${chapterId}/topic/${topicId}`;
        this.service.get(url, true).then((res) => {
            if (res && res.res && res.res.Item) {
                let assignedContents = this.checkIsAlreadyAssigned(res.res.Item.contentDetails);
                let filteredContentDetails = [];
                if (res.res.Item.contentDetails) {
                    filteredContentDetails = this.filterBySelected(res.res.Item.contentDetails);
                }
                this.setState((prevState) => {
                    return {
                        content: res.res.Item,
                        isLoading: false,
                        selectedContent: assignedContents,
                        filteredContentDetails
                    }
                });
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: ' ', life: 3000 });
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });
    }


    onSelectResourceType = (type) => {


        let selectedTopicResourceTypes = this.state.selectedTopicResourceTypes;
        let selectedTopicResourceType;
        selectedTopicResourceTypes = selectedTopicResourceTypes.map(r => {
            if (type.value == r.value) {
                r.isSelected = true;

                selectedTopicResourceType = r.value
            } else {
                r.isSelected = false
            }
            return r;
        });

        let contentDetails = (this.state.content && this.state.content.contentDetails) ? this.state.content.contentDetails : [];
        contentDetails = this.filterResource(contentDetails, selectedTopicResourceTypes)
        this.setState({
            selectedTopicResourceTypes,
            filteredContentDetails: contentDetails
        });
    }


    onSelectContent = (e) => {
        this.setState({ selectedContent: e.value }, this.props.onSelectContent(e.value, this.state.content))
    }

    onAssignVideos = () => {
        let { selectedContent } = this.state;
        if (selectedContent && selectedContent.length) {
            this.props.onAssignVideos(selectedContent);
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select atleast one video to assign', life: 3000 });
        }

    }

    render() {
        console.log(this.state, 'state')
        const header = (
            <div className="table-header ma-tbl-header">
                {/* <div className="ma-tbl-left">
                    {
                        this.state.selectedTopicResourceTypes && this.state.selectedTopicResourceTypes.length ? this.state.selectedTopicResourceTypes.map((type) => {
                            return <Button className={`ma-ml10 ${!type.isSelected ? 'p-button-outlined' : ''}`} label={type.label} onClick={() => this.onSelectResourceType(type)} />
                        }) : null
                    }
                </div> */}

            </div>
        );

        // console.log('boardsc', this.props.boards)
        return <Dialog
            //visible={this.state.isShowCreate}
            style={{ width: '90%', height: '80vh' }}
            //  header={this.props.isAdd ? "Create Role" : "Edit Role"}
            modal
            blockScroll={true}
            footer={() => {
                return (<div className='ma-mt20'>
                    <Button label="Cancel" className='p-button-outlined' onClick={this.props.onHide} />
                    <Button label={`Assign`} className='ma-m-lr10' onClick={this.onAssignVideos} />
                </div>)
            }}
            draggable={false}
            // footer={this.footer}
            header={null}
            closeOnEscape={false}

            dismissableMask={false}
            className='add-a-resource-dialog'
            visible={true}
            closable={false}
            //header="Add a Resource"
            onHide={this.props.onHide}>





            <div className='add-a-resource' >
                {/* <p>Select Source</p>
                <RadioButton className="radio-inline" value='content' inputId="content" name="uploadType" onChange={(e) => this.setState({ uploadType: 'content' })} checked={this.state.uploadType === 'content'} />
                <label className='ma-label-s1' htmlFor="content">Content Master</label>
                <RadioButton className="radio-inline ma-ml10" value='thirdparty' inputId="thirdparty" name="uploadType" onChange={(e) => this.setState({ uploadType: 'thirdparty' })} checked={this.state.uploadType === 'thirdparty'} />
                <label className='ma-label-s1' htmlFor="thirdparty">Third Party</label> */}

                <div className='ma-mt20' >
                    {/* <Dropdown value={this.state.boardId}
                            className='ma-w200'
                            options={this.props.boards}
                            optionLabel='boardName'
                            optionValue='boardId'
                            onChange={(e) => this.onChangeBoard(e.value)}
                            placeholder="Select Board" />
                        <Dropdown value={this.state.classId}
                            options={this.state.classes}
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                        <Dropdown value={this.state.subjectId}
                            options={this.state.subjects}
                            optionLabel='subjectName'
                            optionValue='subjectId'
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                        <Dropdown value={this.state.chapterId}
                            options={this.state.chapters}
                            optionLabel='chapterName'
                            optionValue='chapterId'
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />
                        <Dropdown value={this.state.topicId}
                            options={this.state.topics}
                            optionLabel='topicName'
                            optionValue='topicId'
                            className='ma-m-lr10 ma-w200'
                            onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" /> */}
                    {/* <Button label="Fetch Resources" className=' ma-m-lr10' /> */}

                    <div className="card datatable-crud-demo ">
                        {/* {this.state.filteredContentDetails && this.state.filteredContentDetails.length ? <> */}
                        <h3 className='tbl-title ma-mt20'>Available Resources</h3>
                        <DataTable
                            globalFilter={this.state.globalFilter}
                            //  lazy
                            header={header}
                            //  value={this.state.roles}
                            value={this.state.filteredContentDetails || []}
                            // selection={this.state.selectedProducts}
                            // onSelectionChange={(e) => this.setState({ selectedProducts: e.value })}
                            selection={this.state.selectedContent}
                            onSelectionChange={this.onSelectContent}
                            dataKey="contentId"
                            paginator
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            rows={10}
                            responsiveLayout="scroll"
                            selectionMode="checkbox"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                            {/* <Column field="contentFileType" header="Resourse Type" /> */}
                            <Column field="contentName" header="Resource Name" />
                            <Column body={(rowData) => {
                                return (
                                    <div className="preview-c" >

                                        <span onClick={() => {
                                            this.setState({
                                                previewContent: rowData,
                                                isShowPreview: true
                                            });
                                        }} > <i className='pi pi-eye ma-mr10' />Preview </span>
                                    </div>
                                )
                            }} field="status" header="Preview" />
                        </DataTable>

                    </div>


                </div >



                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }
                {this.state.isShowPreview && <ContentPlayer onCloseDialog={() => {
                    this.setState({
                        isShowPreview: false
                    })
                }} contentInfo={_.cloneDeep(this.state.previewContent)} />}
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </Dialog>
    }
}



const mapStateToProps = (state) => ({
    //    boards: state.boardsData.contentRepoBoards,
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    // boardsWithoutMeluha: state.boardsData.boardsWithoutMeluha,
    // activeBoards: state.boardsData.activeBoards,
});

export default connect(mapStateToProps, {
    getBoardsData
})(Authentication(withRouter(PeriodResources)));