import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { TestsForReports } from '../constants';
import { Toast } from 'primereact/toast';

import { Button } from 'primereact/button';
import Authorizer, { PERMISSIONS } from '../../session/authorizer';
import Service from '../../../services';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../../store/apiConstants';
import QuestionWiseReport from './adhockReports/questionWiseReport';
import SubjectWiseReport from './adhockReports/subjectWiseReport';
import SubjectQuestionTypeWiseReport from './adhockReports/subjectQuestionTypeWiseReport';
import LoadingComponent from '../../loadingComponent';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';


export default class AdhocMockTest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            examType: props.editExamType ? props.editExamType : '',
            exams: [],
            examsResult: null
        };

        this.service = new Service();
    }



    componentDidMount() {


        this.getExams();

    }


    getExams = () => {
        this.setState({
            isLoading: true
        })


        this.service.get(`${baseUrlAdmin}/examination/getallexams/examtype/AdhocMockTest`, true).then(data => {
            if (data && data.res && data.res.Items) {
                this.setState({
                    exams: data.res.Items,
                    isLoading: false
                });
            } else {
                this.setState({
                    exams: [],
                    isLoading: false
                });

                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: '', life: 3000 });
            }

        }).catch(e => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    getUrl = (reportType, examId) => {

        switch (reportType) {
            case 1:
                return `${baseUrlForLongRunningTask}/results/get-all/test/${examId}/results`;
            case 2:
                return `${baseUrlForLongRunningTask}/results/get/test/${examId}/marking`;
            case 3:
                return `${baseUrlForLongRunningTask}/results/get/test/${examId}/report3`;
            default:
                return '';
        }
    }

    getReport = (reportType) => {
        const { examId } = this.state;



        if (examId) {
            this.setState({
                isLoading: true,
                reportType,
                examsResult: null
            })
            const url = this.getUrl(reportType, examId)
            this.service.get(url, true).then(data => {

                if (data && data.res && data.res.examResult) {
                    this.setState({
                        examsResult: data.res.examResult,
                        isLoading: false
                    });
                } else {

                    this.setState({
                        isLoading: false
                    })
                }



            }).catch(e => {

                this.setState({
                    isLoading: false
                })


            })
        }
    }



    render() {
        const { examsResult } = this.state;
        //   console.log(this.state)
        return (<>
            <InputTextB info={FIELDS_INFO.EXAM_SELECT} id="acd">
                <Dropdown value={this.state.examId}
                    className='ma-w200'
                    options={this.state.exams}
                    optionLabel='examName'
                    optionValue='examId'
                    onChange={(e) => this.setState({ examId: e.value })}
                    placeholder="Select Exam" />
            </InputTextB>
            <div className='ma-mt20'>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_QUESTION_WISE} >
                    <Button label="Question Wise" onClick={() => {
                        this.getReport(1)
                    }} className={`ma-mr10 ${this.state.reportType === 1 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_SUBJECT_WISE} >
                    <Button label="Subject Wise" onClick={() => {
                        this.getReport(2)
                    }} className={`ma-mr10  ${this.state.reportType === 2 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
                <Authorizer permId={PERMISSIONS.EXAM_RESULT_ADHOC_SUBJECT_AND_Q_WISE} >
                    <Button label="Subject and Question Type Wise" onClick={() => {
                        this.getReport(3)
                    }} className={`${this.state.reportType === 3 ? '' : 'p-button-outlined'}`} />
                </Authorizer>
            </div>
            {this.state.reportType === 1 && (
                <QuestionWiseReport examsResult={examsResult} />
            )}
            {this.state.reportType === 2 && (
                <SubjectWiseReport examsResult={examsResult} />
            )}
            {this.state.reportType === 3 && (
                <SubjectQuestionTypeWiseReport examsResult={examsResult} />
            )}


            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}
