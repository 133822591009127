import React, { Component } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { TestsForReports } from '../constants';
import AdhocMockTest from './adhocMockTest';
import Authorizer, { PERMISSIONS, isAuthorized, getPermIdOfAction } from '../../session/authorizer';
import Authentication from './../../session';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FIELDS_INFO } from '../../../constants';
import InputTextB from '../../customComponents/inputTextB';
import AdhocMockTest2 from './adhocMockTest2';

import './styles.scss'
class ExamResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            examType: props.editExamType ? props.editExamType : '',
            testTypes: []
        };
    }




    componentDidMount() {
        if (this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [];

                TestsForReports.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-result'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevProps.permissionIds !== this.props.permissionIds && this.props.userInfo) {
            if (this.props.permissionIds && this.props.permissionIds.length) {

                let testTypes = [];

                TestsForReports.forEach((t) => {
                    if ((isAuthorized(this.props.permissionIds, getPermIdOfAction(t.value + '-create'), this.props.userInfo))) {
                        testTypes.push(t);
                    }
                });

                this.setState({
                    testTypes
                })

            }
        }
    }




    render() {
        return (<>
            <InputTextB info={FIELDS_INFO.TEST_TYPE} id="leRAbT">
                <Dropdown value={this.state.examType}
                    className='ma-w200'
                    options={this.state.testTypes}
                    onChange={(e) => this.setState({ examType: e.value })}
                    placeholder="Select Test Type" />
            </InputTextB>
            <div className='ma-mt20'>
                {
                    this.state.examType == 'AdhocMockTest' && <AdhocMockTest />
                }

                {
                    this.state.examType == 'AdhocMockTestT2' && <AdhocMockTest2 />
                }
            </div>
        </>)
    }
}



const mapStatesToProps = (state) => ({
    permissionIds: state.currentUserPerms && state.currentUserPerms.permissionIds && state.currentUserPerms.permissionIds,
    userInfo: state.currentUserPerms && state.currentUserPerms.userInfo ? state.currentUserPerms.userInfo : {},
});




export default connect(mapStatesToProps, {})(Authentication(withRouter(ExamResults)));