//    const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

import React, { Component } from "react";
import { connect } from "react-redux";
import { RadioButton } from "primereact/radiobutton";
import withRouter from "react-router-dom/withRouter";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import SvgViewer from "./../customComponents/svgViewer";
import { Checkbox } from "primereact/checkbox";
import Authentication from "../session";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { BreadCrumb } from "primereact/breadcrumb";
//import {ModuleMenuItems} from './../../constants';
import "./styles.scss";
import Table from "../table";
import TableDynamic from "../tableDynamic";
import LazyTable from "../lazyTable";
import { TieredMenu } from "primereact/tieredmenu";

import LoadingComponent from "../loadingComponent";
import RoleChange from "./RoleChange";
import { PanelMenu } from "primereact/panelmenu";
import { Dropdown } from "primereact/dropdown";
import { I18Next } from "../../internationalization";
import { getAllLanguageArrayList } from "../../utile/formHelper";
import { userSignOut, setLanguage } from "./../../store/actions";

// const items = [
//     { label: 'Categories' },
//     { label: 'Sports' },
//     { label: 'Football' },
//     { label: 'Countries' },
//     { label: 'Spain' },
//     { label: 'F.C. Barcelona' },
//     { label: 'Squad' },
//     { label: 'Lionel Messi' }
// ];

const home = { icon: "pi pi-home" };

const getThemes = () => {
  if (localStorage.getItem("themes")) {
    return JSON.parse(localStorage.getItem("themes"));
  } else {
    return [
      {
        name: "Light Blue",
        value: null,
        isSelected: true,
      },
      {
        name: "Pink",
        value: "pink",
        isSelected: false,
      },
      {
        name: "Green",
        value: "green",
        isSelected: false,
      },
      {
        name: "Yellow",
        value: "yellow",
        isSelected: false,
      },
    ];
  }
};

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themes: getThemes(),
      isShowChangeRole: false,
      defaultLanguage: "en",
    };
  }

  componentDidMount() {
    // if (!this.props.assignedModules) {
    //     this.props.getOrgData();
    // }
    const storedName = localStorage.getItem("defaultLanguage");
    if (storedName) {
      this.setState({
        defaultLanguage: storedName,
      });
    }
  }

  getNavbarModules = (langType) => {
    let ModuleMenuItems = [
      {
        label: I18Next("home", langType).COMMON_KEY,
        // className: `${window.location.pathname == '/' ? 'ma-menu-active' : ''}`,
        command: () => {
          this.props.history.push("/");
        },
        //command: () => { console.log('abcdef'); },
        //url: '/home'
        isPermRequired: false,
      },
      {
        label: I18Next("organization", langType).COMMON_KEY,
        className: `${
          window.location.pathname == "/organization" ||
          window.location.pathname == "/boards" ||
          window.location.pathname == "/branch"
            ? "ma-menu-active"
            : ""
        }`,
        // command: () => { this.props.history.push('/organization') },
        //command: () => { console.log('abcdef'); },
        //url: '/home'
        isPermRequired: false,

        items: [
          {
            label: I18Next("organizationMaster", langType).COMMON_KEY,
            // label: 'Organization Master',
            // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : ''}`,
            command: () => {
              this.props.history.push("/organization");
            },
            isPermRequired: true,
            uId: "8661adca-b5c5-49e0-abe1-67ea1f6839c4",
            // icon: 'pi pi-fw pi-align-left'
          },
          {
            label: I18Next("boardsMaster", langType).COMMON_KEY,
            // label: 'Boards Master',
            // className: `${window.location.pathname == '/boards' ? 'ma-sub-menu-active' : ''}`,
            command: () => {
              this.props.history.push("/boards");
            },
            isPermRequired: true,
            uId: "5311cf61-a84f-433b-8edf-b61134f5acf3",
            // icon: 'pi pi-fw pi-align-left'
          },
          {
            label: I18Next("branchMaster", langType).COMMON_KEY,
            // label: 'Branch Master',
            command: () => {
              this.props.history.push("/branch");
            },
            // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
            isPermRequired: true,
            uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
            //  icon: 'pi pi-fw pi-align-right'
            //  className: "ma-sub-menu-active",
          },
          {
            label: I18Next("admissions", langType).COMMON_KEY,
            // label: 'Admissions',
            // isPermRequired: true,
            // uId: '0a1f9cfd-9239-495d-bbb4-47c05233f5e1',
            items: [
              {
                label: I18Next("admissionCreate", langType).COMMON_KEY,
                // label: 'Admission',
                command: () => {
                  this.props.history.push("/admission-create");
                },
              },
              {
                label: I18Next("academicYears", langType).COMMON_KEY,
                // label: 'Academic Years',
                // isPermRequired: true,
                // uId: "2b489bee-433e-4d1b-af1c-de0e3558f15e",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/academic-years");
                },
              },
              {
                label: I18Next("admissionTypes", langType).COMMON_KEY,
                // label: 'Admission Types',
                // isPermRequired: true,
                // uId: "b83aad90-8a37-4890-a3e9-e64011c60cd2",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/admission-types");
                },
              },
              {
                label: I18Next("applicationFee", langType).COMMON_KEY,
                // label: 'Application Fee',
                command: () => {
                  this.props.history.push("/application-fees");
                },
                // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
                // isPermRequired: true,
                // uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
                //  icon: 'pi pi-fw pi-align-right'
                //  className: "ma-sub-menu-active",
              },
              {
                label: I18Next("admissionCourse", langType).COMMON_KEY,
                // label: 'Admission Course',
                command: () => {
                  this.props.history.push("/admission-courses");
                },
              },
            ],
          },
          {
            label: I18Next("finance", langType).COMMON_KEY,
            // label: 'Finance',
            items: [
              {
                label: I18Next("busFeePlans", langType).COMMON_KEY,
                // label: 'Bus Fee Plans',
                // isPermRequired: true,
                // uId: "2b489bee-433e-4d1b-af1c-de0e3558f15e",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/bus-fee-plans");
                },
              },
              {
                label: I18Next("feeCollect", langType).COMMON_KEY,
                // label: 'Fee Collect',
                command: () => {
                  this.props.history.push("/fee-collect");
                },
              },
              {
                label: I18Next("feeDaySheet", langType).COMMON_KEY,
                // label: 'Fee Day Sheet',
                command: () => {
                  this.props.history.push("/fee-day-sheet");
                },
              },
              {
                label: I18Next("feeTypes", langType).COMMON_KEY,
                // label: 'Fee Types',
                command: () => {
                  this.props.history.push("/fee-types");
                },
              },
              {
                label: I18Next("feePlans", langType).COMMON_KEY,
                // label: 'Fee Plans',
                command: () => {
                  this.props.history.push("/fee-plans");
                },
              },
            ],
            // command: () => {
            //     this.props.history.push('/finance')
            // },
            // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
            // isPermRequired: true,
            // uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
            //  icon: 'pi pi-fw pi-align-right'
            //  className: "ma-sub-menu-active",
          },
          {
            label: I18Next("transport", langType).COMMON_KEY,
            // label: 'Transport',
            // isPermRequired: true,
            // uId: '0a1f9cfd-9239-495d-bbb4-47c05233f5e1',
            items: [
              {
                label: I18Next("vehicleManagement", langType).COMMON_KEY,
                // label: 'Vehicle Management',
                // isPermRequired: true,
                // uId: "2b489bee-433e-4d1b-af1c-de0e3558f15e",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/vehicle-management");
                },
              },
              {
                label: I18Next("transportStaffManagement", langType).COMMON_KEY,
                // label: 'Transport Staff Management',
                // isPermRequired: true,
                // uId: "b83aad90-8a37-4890-a3e9-e64011c60cd2",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/transport-staff-management");
                },
              },
              {
                label: I18Next("routeManagement", langType).COMMON_KEY,
                // label: 'Route Management',
                command: () => {
                  this.props.history.push("/route-management");
                },
                // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
                // isPermRequired: true,
                // uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
                //  icon: 'pi pi-fw pi-align-right'
                //  className: "ma-sub-menu-active",
              },
              {
                label: I18Next("transportAttendance", langType).COMMON_KEY,
                // label: 'Transport Attendance',
                command: () => {
                  this.props.history.push("/transport-attendance");
                },
                // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
                // isPermRequired: true,
                // uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
                //  icon: 'pi pi-fw pi-align-right'
                //  className: "ma-sub-menu-active",
              },
              {
                label: I18Next("transportAttendanceReport", langType)
                  .COMMON_KEY,
                // label: 'Transport Attendance Report',
                command: () => {
                  this.props.history.push("/transport-attendance-report");
                },
                // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
                // isPermRequired: true,
                // uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
                //  icon: 'pi pi-fw pi-align-right'
                //  className: "ma-sub-menu-active",
              },
            ],
          },
          {
            label: "QR Code Manager",
            command: () => {
              this.props.history.push("/qr-code-manager");
            },
            // className: `${window.location.pathname == '/branch' ? 'ma-sub-menu-active' : ''}`,
            isPermRequired: true,
            // uId: "5311cf61-a84f-433b-8edf-b71134f5acf3",
            //  icon: 'pi pi-fw pi-align-right'
            //  className: "ma-sub-menu-active",
          },
        ],
      },
      {
        label: I18Next("academics", langType).COMMON_KEY,
        isPermRequired: false,
        className: `${
          window.location.pathname == "/curriculum" ||
          window.location.pathname == "/assign-content" ||
          window.location.pathname == "/content" ||
          window.location.pathname == "/virtualclass"
            ? "ma-menu-active"
            : ""
        }`,
        //   icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: I18Next("curriculumMaster", langType).COMMON_KEY,
            // label: 'Curriculum Master',
            command: () => {
              this.props.history.push("/curriculum");
            },
            // className: `${window.location.pathname == '/curriculum' ? 'ma-sub-menu-active' : ''}`,
            isPermRequired: true,
            uId: "aa4160b6-9d04-48d3-a090-da8cae2f5b06",
            // icon: 'pi pi-fw pi-align-left'
          },

          {
            label: I18Next("assignContent", langType).COMMON_KEY,
            // label: 'Assign Content',
            command: () => {
              this.props.history.push("/assign-content");
            },
            // className: `${window.location.pathname == '/assign-content' ? 'ma-sub-menu-active' : ''}`,
            isPermRequired: true,
            uId: "86e09c71-cb58-4515-9eb5-7f0180abefff",
            // icon: 'pi pi-fw pi-align-left'
          },
          {
            label: I18Next("contentMaster", langType).COMMON_KEY,
            // label: 'Content Master',
            isPermRequired: true,
            uId: "e2e589db-0ce1-4fd9-aba5-929b772a5507",
            // className: `${window.location.pathname == '/content' ? 'ma-sub-menu-active' : ''}`,
            command: () => {
              this.props.history.push("/content");
            },
            //  icon: 'pi pi-fw pi-align-right'
          },
          {
            label: I18Next("contentBulkUpload", langType).COMMON_KEY,
            // label: 'Content Bulk Upload',
            isPermRequired: true,
            uId: "86c74e53-6898-4b7c-aafd-2ec5c44fe577",
            // className: `${window.location.pathname == '/content' ? 'ma-sub-menu-active' : ''}`,
            command: () => {
              this.props.history.push("/content-bulkupload");
            },
            //  icon: 'pi pi-fw pi-align-right'
          },
          {
            label: I18Next("yearlyPlanner", langType).COMMON_KEY,
            // label: 'Yearly Planner',

            // className: `${window.location.pathname == '/assign-content' ? 'ma-sub-menu-active' : ''}`,
            //  isPermRequired: true,
            // uId: "ac42b033-6283-4f1b-bf2a-42bd31a93263",
            items: [
              {
                label: I18Next("yearlyPlanner", langType).COMMON_KEY,
                // label: 'Yearly Planner',
                isPermRequired: true,
                uId: "ac42b033-6283-4f1b-bf2a-42bd31a93263",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/yearlyplanner");
                },
              },
              {
                label: I18Next("yearlyPlannerBulkupload", langType).COMMON_KEY,
                // label: 'Yearly Planner Bulk upload',
                isPermRequired: true,
                uId: "9cfbed54-6702-4a81-8067-42a7caf2dc47",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/yearlyplanner-bulk");
                },
              },
              {
                label: I18Next("timeTable", langType).COMMON_KEY,
                // label: 'Time Table',
                isPermRequired: true,
                uId: "dfebb14d-f1a4-40e2-82a0-0d486512cf1c",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/timetable");
                },
              },
            ],
            // icon: 'pi pi-fw pi-align-left'
          },

          {
            label: I18Next("examination", langType).COMMON_KEY,
            // label: 'Examination',
            isPermRequired: true,
            uId: "0a1f9cfd-9239-495d-bbb4-47c05233f5e1",
            items: [
              {
                label: I18Next("questionBank", langType).COMMON_KEY,
                // label: 'Question Bank',
                isPermRequired: true,
                uId: "2b489bee-433e-4d1b-af1c-de0e3558f15e",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/question-bank");
                },
              },
              {
                label: I18Next("assignQuestion", langType).COMMON_KEY,
                // label: 'Assign Question',
                isPermRequired: true,
                uId: "b83aad90-8a37-4890-a3e9-e64011c60cd2",
                //   icon: 'pi pi-fw pi-filter',
                command: () => {
                  this.props.history.push("/assign-question");
                },
              },
              {
                label: I18Next("exams", langType).COMMON_KEY,
                // label: 'Exams',
                command: () => {
                  this.props.history.push("/examination");
                },
                // icon: 'pi pi-fw pi-bars'
              },
            ],
          },
          {
            label: I18Next("discussionBoard", langType).COMMON_KEY,
            // label: 'Discussion Board',
            //  icon: 'pi pi-fw pi-user-plus',
            command: () => {
              this.props.history.push("/discussion-board");
            },
            isPermRequired: true,
            uId: "e2e589db-0ce1-4fd9-aba5-929b77115507",
          },
          {
            label: I18Next("askADoubt", langType).COMMON_KEY,
            // label: 'Ask a doubt ?',
            //  icon: 'pi pi-fw pi-user-plus',
            command: () => {
              this.props.history.push("/askadoubt");
            },
            isPermRequired: true,
            uId: "e2e589db-0ce1-23d9-aba5-929b77115507",
          },
          {
            label: I18Next("virtualClasses", langType).COMMON_KEY,
            // label: 'Virtual Classes',
            //  icon: 'pi pi-fw pi-user-plus',
            command: () => {
              this.props.history.push("/virtualclass");
            },
            isPermRequired: true,
            uId: "5a049bee-adde-4e1f-8ae5-0783b98c05a4",
          },
          {
            label: I18Next("classRoom", langType).COMMON_KEY,
            // label: 'Class Room',
            //  icon: 'pi pi-fw pi-user-plus',
            command: () => {
              this.props.history.push("/classroom");
            },
            isPermRequired: true,
            uId: "02e301e9-a334-414f-9058-227448708c49",
          },
        ],
      },
      {
        label: I18Next("management", langType).COMMON_KEY,
        isPermRequired: false,
        className: `${
          window.location.pathname == "/admin-manage" ||
          window.location.pathname == "/user-management" ||
          window.location.pathname == "/user-bulkupload"
            ? "ma-menu-active"
            : ""
        }`,
        // icon: 'pi pi-fw pi-user',
        items: [
          //     {
          //     label: I18Next('adminManagement', langType).COMMON_KEY,
          //     // label: 'Admin Management',
          //     //  icon: 'pi pi-fw pi-user-plus',
          //     command: () => {
          //         this.props.history.push('/admin-manage')
          //     },
          //     // className: `${window.location.pathname == '/admin-manage' ? 'ma-sub-menu-active' : ''}`,
          //     isPermRequired: true,
          //     uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c"
          // },
          {
            label: I18Next("adminManagement", langType).COMMON_KEY,
            isPermRequired: true,
            uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
            items: [
              {
                label: I18Next("admins", langType).COMMON_KEY,
                command: () => {
                  this.props.history.push("/admin-manage");
                },
                isPermRequired: false,
                uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
              },
              {
                label: I18Next("bulkUpload", langType).COMMON_KEY,
                command: () => {
                  this.props.history.push("/admins-bulkupload");
                },
                isPermRequired: true,
                uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
              },
            ],
          },
          {
            label: I18Next("userManagement", langType).COMMON_KEY,
            // label: 'User Management',
            // command: () => {
            //     this.props.history.push('/user-management')
            // },
            // className: `${(window.location.pathname == '/user-management' || window.location.pathname == '/user-bulkupload') ? 'ma-sub-menu-active' : ''}`,
            isPermRequired: true,
            uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510b",
            items: [
              {
                label: I18Next("users", langType).COMMON_KEY,
                // label: 'Users',
                command: () => {
                  this.props.history.push("/user-management");
                },
                //   // className: `${window.location.pathname == '/user-management'  ? 'ma-sub-menu-active' : ''}`,
                isPermRequired: false,
                uId: "aa4160b6-9d04-48d3-a090-da8cae2f5b06",
                // icon: 'pi pi-fw pi-align-left'
              },
              {
                label: I18Next("bulkUpload", langType).COMMON_KEY,
                // label: 'Bulk Upload',
                command: () => {
                  this.props.history.push("/user-bulkupload");
                },
                //    // className: `${window.location.pathname == '/user-bulkupload' ? 'ma-sub-menu-active' : ''}`,
                isPermRequired: true,
                uId: "c5002f34-c14c-47f9-8c75-b505b66a435d",
                //  icon: 'pi pi-fw pi-align-right'
              },
            ],
            //  icon: 'pi pi-fw pi-user-minus',
          },
          {
            label: I18Next("approvalMechanismManagement", langType).COMMON_KEY,
            command: () => {
              this.props.history.push("/approval-management");
            },
            isPermRequired: true,
            uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510c",
          },
          {
            label: I18Next("attendance", langType).COMMON_KEY,
            // label: 'Attendance',
            // command: () => {
            //     this.props.history.push('/user-management')
            // },
            className: `${
              window.location.pathname == "/give-attendance" ||
              window.location.pathname == "/upload-attendance" ||
              window.location.pathname == "/attendance-report"
                ? "ma-sub-menu-active"
                : ""
            }`,
            //  isPermRequired: true,
            //  uId: "8fa5852b-bf40-4b95-a2c9-b15c8830510b",
            items: [
              {
                label: I18Next("giveAttendance", langType).COMMON_KEY,
                // label: 'Give Attendance',
                command: () => {
                  this.props.history.push("/give-attendance");
                },
                className: `${
                  window.location.pathname == "/give-attendance"
                    ? "ma-sub-menu-active"
                    : ""
                }`,
                isPermRequired: true,
                uId: "5ad1777b-33f4-4a78-80df-4dcac23e5479",
                // icon: 'pi pi-fw pi-align-left'
              },
              {
                label: I18Next("uploadStudentAttendance", langType).COMMON_KEY,
                // label: 'Upload Student Attendance',
                command: () => {
                  this.props.history.push("/upload-attendance");
                },
                className: `${
                  window.location.pathname == "/upload-attendance"
                    ? "ma-sub-menu-active"
                    : ""
                }`,
                isPermRequired: true,
                uId: "6cd94133-e3df-4a88-ab77-96c4fa5a0e55",
                // icon: 'pi pi-fw pi-align-left'
              },
              {
                label: I18Next("studentAttendanceReport", langType).COMMON_KEY,
                // label: 'Student Attendance Report',
                command: () => {
                  this.props.history.push("/attendance-report");
                },
                className: `${
                  window.location.pathname == "/attendance-report"
                    ? "ma-sub-menu-active"
                    : ""
                }`,
                isPermRequired: true,
                uId: "d3bcd3e8-dd97-4fc8-9c9e-2e2e50b13dbd",
                // icon: 'pi pi-fw pi-align-left'
              },
            ],
            //  icon: 'pi pi-fw pi-user-minus',
          },

          {
            label: I18Next("ERP", langType).COMMON_KEY,
            // label: 'ERP',
            // command: () => {
            //     this.props.history.push('/user-management')
            // },
            // className: `${(window.location.pathname == '/user-management' || window.location.pathname == '/user-bulkupload') ? 'ma-sub-menu-active' : ''}`,
            isPermRequired: true,
            uId: "da62abf2-d3bb-4a86-8166-a53036d26abc",
            command: () => {
              window.open("https://corp6.myclassboard.com", "_self");
            },

            //  icon: 'pi pi-fw pi-user-minus',
          },
        ],
      },
      {
        label: I18Next("analytics", langType).COMMON_KEY,
        isPermRequired: false,
        className: `${
          window.location.pathname == "/usage-analytics" ||
          window.location.pathname == "/exam-analytics"
            ? "ma-menu-active"
            : ""
        }`,
        // icon: 'pi pi-fw pi-user',
        items: [
          {
            label: I18Next("usageAnalytics", langType).COMMON_KEY,
            //label: 'Usage Analytics',
            isPermRequired: true,
            uId: "e2e589db-0ce1-4fd9-aba5-119b77115507",
            items: [
              {
                label: I18Next("usageAnalytics", langType).COMMON_KEY,
                // label: 'Usage Analytics',
                command: () => {
                  this.props.history.push("/usage-analytics");
                },
                //   // className: `${window.location.pathname == '/user-management'  ? 'ma-sub-menu-active' : ''}`,
                isPermRequired: true,
                uId: "2b2a46c7-bdc6-4679-b323-20a1fcc01261",
                // icon: 'pi pi-fw pi-align-left'
              },
              {
                label: I18Next("branchWiseAnalytics", langType).COMMON_KEY,
                // label: 'Branch Wise Analytics',
                command: () => {
                  this.props.history.push("/branch-analytics");
                },
                //    // className: `${window.location.pathname == '/user-bulkupload' ? 'ma-sub-menu-active' : ''}`,
                isPermRequired: true,
                uId: "be3cb0c5-c36f-4925-9a6e-abcc140e3db9",
                //  icon: 'pi pi-fw pi-align-right'
              },
            ],
            //  icon: 'pi pi-fw pi-user-minus',
          },
          {
            label: I18Next("examAnalytics", langType).COMMON_KEY,
            // label: 'Exam Analytics',
            command: () => {
              this.props.history.push("/exam-analytics");
            },
            isPermRequired: true,
            uId: "d44b1124-3386-4ddf-96d8-5c63ce600f3b",
          },
          {
            label: I18Next("teacherAnalytics", langType).COMMON_KEY,
            // label: 'Teacher Analytics',
            command: () => {
              this.props.history.push("/teacher-analytics");
            },
            isPermRequired: true,
            uId: "a7c422bc-5f4b-4af5-817b-4defa08aa158",
          },
        ],
      },
      {
        label: I18Next("support", langType).COMMON_KEY,
        isPermRequired: false,
        className: `${
          window.location.pathname == "/notifications" ? "ma-menu-active" : ""
        }`,

        items: [
          {
            label: I18Next("notificationManagement", langType).COMMON_KEY,
            // label: 'Notification Management',
            command: () => {
              this.props.history.push("/notifications");
            },
            uId: "ee994199-e837-4005-83ee-16c2a5156da6",
            isPermRequired: true,
          },
          {
            label: I18Next("enquiryForm", langType).COMMON_KEY,
            // label: 'Enquiry Form',
            command: () => {
              this.props.history.push("/enquiry-from");
            },
            // uId: "",
            // isPermRequired: true
          },
          {
            label: I18Next("ticketMangement", langType).COMMON_KEY,
            // label: 'Enquiry Form',
            command: () => {
              this.props.history.push("/ticket-management");
            },
            // uId: "",
            // isPermRequired: true
          },
        ],
      },
    ];

    let assignedM = [];

    if (this.props.userInfo && this.props.userInfo.isSuperAdmin) {
      return ModuleMenuItems;
    }

    if (
      this.props.assignedModules &&
      this.props.assignedModules.length &&
      this.props.permissionIds &&
      this.props.permissionIds.length
    ) {
      let assignedModules = this.props.assignedModules.map((m) => m.id);
      assignedM = ModuleMenuItems.filter((item) => {
        if (item.isPermRequired) {
          //
          if (
            assignedModules.includes(item.uId) &&
            this.props.permissionIds.includes(item.uId)
          ) {
            if (item.items) {
              item.items = item.items.filter((subItem) => {
                if (subItem.isPermRequired) {
                  //
                  if (assignedModules.includes(subItem.uId)) {
                    if (subItem.items && subItem.items.length) {
                      subItem.items = subItem.items.filter((subItem2) => {
                        if (subItem2.isPermRequired) {
                          if (this.props.permissionIds.includes(subItem2.uId)) {
                            return subItem2;
                          }
                        } else {
                          return subItem2;
                        }
                      });
                    }
                    return subItem;
                  }
                } else {
                  if (subItem.items && subItem.items.length) {
                    subItem.items = subItem.items.filter((subItem2) => {
                      if (subItem2.isPermRequired) {
                        if (this.props.permissionIds.includes(subItem2.uId)) {
                          return subItem2;
                        }
                      } else {
                        return subItem2;
                      }
                    });
                  }
                  return subItem;
                }
              });
            }

            if (item && item.items && item.items.length) {
              return item;
            }
          }
        } else {
          if (item.items) {
            item.items = item.items.filter((subItem) => {
              if (subItem.isPermRequired) {
                if (
                  assignedModules.includes(subItem.uId) &&
                  this.props.permissionIds.includes(subItem.uId)
                ) {
                  if (subItem.items && subItem.items.length) {
                    subItem.items = subItem.items.filter((subItem2) => {
                      if (subItem2.isPermRequired) {
                        if (this.props.permissionIds.includes(subItem2.uId)) {
                          return subItem2;
                        }
                      } else {
                        return subItem2;
                      }
                    });
                  }
                  return subItem;
                }
              } else {
                if (subItem.items && subItem.items.length) {
                  subItem.items = subItem.items.filter((subItem2) => {
                    if (subItem2.isPermRequired) {
                      if (this.props.permissionIds.includes(subItem2.uId)) {
                        return subItem2;
                      }
                    } else {
                      return subItem2;
                    }
                  });
                }
                return subItem;
              }
            });
          } else {
            return item;
          }
          if (item && item.items && item.items.length) {
            return item;
          }
        }
      });
    }

    assignedM = assignedM.filter((i) => {
      if (i && i.items) {
        if (i.items.length) {
          i.items = i.items.filter((j) => {
            if (j && j.items) {
              if (j.items.length) {
                j.items = j.items.filter((k) => {
                  if (k && k.items) {
                    if (k.items.length) {
                      return k;
                    }
                  } else {
                    if (k.command) {
                      return k;
                    }
                  }
                });
                if (j.items.length) {
                  return j;
                }
              }
            } else {
              if (j.command) {
                return j;
              }
            }
          });

          if (i.items.length) {
            return i;
          }
        }
      } else {
        if (i.command) {
          return i;
        }
      }
    });
    return assignedM;
  };

  changeTheme = (selectedTheme) => {
    const themes = this.state.themes.map((theme) => {
      if (theme.value == selectedTheme.value) {
        theme.isSelected = true;
      } else {
        theme.isSelected = false;
      }
      return theme;
    });
    this.setState({
      themes,
    });

    localStorage.setItem("themes", JSON.stringify(themes));
    localStorage.setItem("data-theme", selectedTheme.value);
    document.documentElement.setAttribute(
      "data-theme",
      localStorage.getItem("data-theme")
    );
    //  window.location.reload();
  };

  getThemes = () => {
    const { themes } = this.state;
    return (
      <ul className="ma-p10">
        {themes.map((theme) => {
          return (
            <li key={theme.name} className="ma-m10">
              <RadioButton
                className="radio-inline"
                value={theme.value}
                inputId={theme.value}
                name="theme"
                onChange={() => {
                  this.changeTheme(theme);
                }}
                checked={theme.isSelected}
              />
              <label className="ma-label-s1" htmlFor={theme.value}>
                {theme.name}
              </label>
            </li>
          );
        })}{" "}
      </ul>
    );
  };
  showRoleChange = () => {
    this.setState({
      isShowChangeRole: true,
    });
  };

  setDefaultLanguage = (langeVal) => {
    localStorage.setItem("defaultLanguage", langeVal);
    this.props.setLanguage(langeVal);

    this.setState({
      defaultLanguage: langeVal,
    });
  };
  render() {
    const items = [
      {
        label: "Theme",
        icon: "pi pi-slack",
        items: [
          {
            template: <>{this.getThemes()}</>,
          },
        ],
      },

      {
        separator: true,
      },
      {
        label: "Log Out",
        icon: "pi pi-fw pi-power-off",
        command: () => {
          this.props.userSignOut();
        },
      },
    ];

    if (
      this.props.userInfo &&
      this.props.userInfo &&
      this.props.userInfo.canChangeRole
    ) {
      items.splice(1, 0, {
        label: "Change Role",
        icon: "pi pi-user",
        command: () => {
          this.showRoleChange();
        },
      });
    }

    const { logoRectangle } = this.props;
    let defaultLang = getAllLanguageArrayList?.find(
      (vl) => vl?.code === this?.state?.defaultLanguage
    );
    return (
      <div className="ma-navbar">
        <div className="p-grid ma-menubar-w">
          <div className="p-col-12 p-md-1 ma-no-p">
            {logoRectangle?.storageLocation && (
              <img
                src={`${
                  logoRectangle?.storageLocation
                    ? logoRectangle?.storageLocation
                    : ""
                }`}
                className="ma-logo"
              />
            )}
          </div>
          <div className="p-col-12 p-md-11">
            <div className="p-grid ma-no-p ma-menubar">
              <div className="p-col-12 p-md-8 ma-no-p">
                <Menubar
                  model={this.getNavbarModules(this?.state?.defaultLanguage)}
                  className="m-menu-bar "
                />
              </div>
              <div className="p-col-12 p-md-2">
                <Dropdown
                  value={defaultLang}
                  onChange={(e) => this.setDefaultLanguage(e.value.code)}
                  options={getAllLanguageArrayList}
                  optionLabel="name"
                  className="w-full md:w-14rem"
                  placeholder="Select a language"
                  style={{ float: "right" }}
                />
              </div>
              <div className="p-col-12 p-md-2 ma-right ">
                <div style={{ width: "40px", float: "right" }}>
                  <img
                    src={"./images/faculty.jpg"}
                    onClick={(event) => this.menu.toggle(event)}
                    style={{ height: "34px", borderRadius: "50%" }}
                  />
                </div>
                <div style={{ marginRight: "50px" }}>
                  <p style={{ textAlign: "right" }} className="ma-profile">
                    {/* <i className='pi pi-angle-down'></i> */}
                    {this.props.userName}
                  </p>
                </div>
                {/* <Dropdown 
                                    value={defaultLang} 
                                    onChange={(e) => this.setDefaultLanguage(e.value.code)} 
                                    options={getAllLanguageArrayList} 
                                    optionLabel="name" 
                                    className="w-full md:w-14rem" 
                                    placeholder="Select a language" /> */}
              </div>
            </div>
            {/* <Menu
                            className='log-out-menu'
                            model={[
                                {
                                    template: (<div className='log-out'>
                                        <ul>
                                            <li onClick={this.props.userSignOut} > <SvgViewer className='ma-icon-svg ma-pointer' src='new-assets/logout.svg' width="16" height="16" /><span>Log out</span></li>
                                            <li onClick={this.props.userSignOut} > <i className='pi pi-slack' /><span>Theme</span></li>
                                        </ul>
                                    </div>)
                                }]
                            }
                            popup
                            ref={el => this.menu = el} /> */}
            <TieredMenu model={items} popup ref={(el) => (this.menu = el)} />
            {/* <Button label="Show" icon="pi pi-bars" onClick={(event) => this.menu.current.toggle(event)} /> */}
          </div>

          {this.props.isLoading && <LoadingComponent />}

          {this.state.isShowChangeRole && (
            <RoleChange
              onCloseDialog={() => {
                this.setState({ isShowChangeRole: false });
              }}
              _id={this.props.userInfo && this.props.userInfo._id}
              currentRole={
                this.props.userInfo &&
                this.props.userInfo.roles &&
                this.props.userInfo.roles[0]
              }
            />
          )}
        </div>
        {/* <div>
                    <PanelMenu model={this.getNavbarModules()} style={{ width: '300px' }} />
                </div> */}

        <div className="ma-main-body">{this.props.children}</div>
        <div className="ma-footer">
          <p className="">Powered by ©CELKON</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userName: localStorage.getItem("name") || "Admin",
  isAdmin: localStorage.getItem("isAdmin"),
  picture: "./360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
  assignedModules: state.orgData.assignedModules,
  logoRectangle: state.orgData.logoRectangle,
  orgName: state.orgData.name,
  isLoading: state.orgData.isLoading,
  isSuperOrg: state.orgData.isSuper,
  permissionIds:
    state.currentUserPerms && state.currentUserPerms.permissionIds
      ? state.currentUserPerms.permissionIds
      : [],
  userInfo:
    state.currentUserPerms && state.currentUserPerms.userInfo
      ? state.currentUserPerms.userInfo
      : {},
  defaultLanguage: state.languageReducer.language,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (defaultLanguage) => dispatch(setLanguage(defaultLanguage)),
  userSignOut: () => dispatch(userSignOut()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication(withRouter(Navbar)));
