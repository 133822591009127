import React, { Component } from 'react'
import { connect } from 'react-redux';

import { Dialog } from 'primereact/dialog';
import withRouter from 'react-router-dom/withRouter';
import { Password } from 'primereact/password';
import { Tooltip } from 'primereact/tooltip';
import LoadingComponent from '../loadingComponent';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import PasswordText from './../customComponents/passwordText';
//import { S3_UPLOAD_CONFIG, StaticConstants } from './../../constants'
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import InputTextB from './../customComponents/inputTextB';
import { selectPermissions } from '../../store/selectors/authorize';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl } from '../../utile/formHelper';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import { getESTDYears, geCountries, States, StatesAndDirstricts, getMeluhaBoard, getActiveBoards, handleDownload, trimObj, getServerTime } from './../../utile'
import InputText2 from './../customComponents/inputText2';
import EMailText from './../customComponents/emailText';
import InputText3 from './../customComponents/inputText3';
import ValidateOTP from './../customComponents/validateOTP'
import DatePicker from "react-datepicker";
import FileUpload from '../fileUpload';
import AssignCurriculums from './assignCurriculums';
import {
    getBoardsData
} from './../../store/actions';
import { getPermissionList } from '../../store/actions/getPermissionsAction';
import Service from '../../services';
import { Steps } from 'primereact/steps';
import moment from 'moment';
import { FIELDS_INFO } from '../../constants';
import { AutoComplete } from 'primereact/autocomplete';
import uuidv4 from 'uuid/v4';
const items = [

    { label: 'Organization', className: 'start-1' },
    { label: 'Curriculums' },
    { label: 'Module' },
    { label: 'Admin User', className: 'end-1' }
];

const adminUserFormFields = [
    {
        "Type": "Text",
        "Label": "Username",
        "FieldName": "username",
        "MinLength": 5,
        "MaxLength": 20,
        "Required": true,
    },
    {
        "Type": "Password",
        "Label": "Password",
        "FieldName": "password",
        "Required": true,
        "MinLength": 8,
    },
    {
        "Type": "Password",
        "Label": "Confirm Password",
        "FieldName": "confirmPassword",
        "Required": true,
        "MinLength": 8,
    },
]


var formFields = require('./createOrganization.json');

class CreateOrganization extends Component {


    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, this.props.editOrganization || {});
        this.formFields2 = getFormFields(adminUserFormFields, (this.props.editOrganization && this.props.editOrganization.superAdmin) ? {
            ...this.props.editOrganization && this.props.editOrganization.superAdmin,
            username: this.props.editOrganization.superAdmin.username.substring(3),
            password: 'Test@123', confirmPassword: 'Test@123'
        } : {});
        this.state = {
            org: this.formFields.data,
            formValidations: this.formFields.formValidations,
            user: this.formFields2.data,
            adminUserFormValidations: this.formFields2.formValidations,
            citiesinSelectState: [],
            currentStep: 0,
            classes: [],
            //assignedCurriculums: [{ id: 1, assigned: false }],
            curriculumTempIndex: 1,
            allModules: [],
            verify: {
                emailOperationalOtp: '',
                emailOperational: false,
                emailOperationalOtpSent: false,
                emailOperationalOtpSentCount: 0,
                emailAccountsOtp: '',
                emailAccounts: false,
                emailAccountsOtpSent: false,
                emailAccountsOtpSentCount: 0,
                mobileOperationalOtp: '',
                mobileOperational: false,
                mobileOperationalOtpSent: false,
                mobileOperationalOtpSentCount: 0,
                mobileAccountsOtp: '',
                mobileAccounts: false,
                mobileAccountsOtpSent: false,
                mobileAccountsOtpSentCount: 0,
            },
            isSubmitClick: false,
            isDomainChecked: this.props.editOrganization ? true : false,
            isDomainAvaliable: this.props.editOrganization ? true : false,
            domainErrMessage: '',
            isShowCreateSuccess: false,
            locations: [],
            locationText: '',
            assignedCurriculums: (this.props.editOrganization && this.props.editOrganization.assignedCurriculums ) ? this.props.editOrganization.assignedCurriculums.map((i, index) => {
                return {
                    id: uuidv4(),
                    assigned: true,
                    data: i
                };
            }) : []
            //{ id: 1, assigned: false }
        }

        this.service = new Service();
    }



    componentDidMount() {
        // if (!this.props.meluhaBoard || !this.props.meluhaBoard.length) {
        //     this.props.getBoardsData();
        // } else {
        //     if (this.props.meluhaBoard) {
        //         this.setState({
        //             classes: this.props.meluhaBoard.classes
        //         });
        //     }
        // }

        //if (!this.props.permissionsMasterList.length) {
        this.props.getPermissions();
        //}


        if (this.props.editOrganization) {

            let selecteState = StatesAndDirstricts.filter((i) => i.State == this.props.editOrganization.state);
            let citiesinSelectState = selecteState[0].Districts.map((state) => {
                return { label: state, value: state }
            });

            let allModules = this.props.permissionsMasterList.map((p) => {
                let module = this.props.editOrganization.assignedModules.filter(m => m.id == p.id)

                return {
                    groupName: p.groupName,
                    id: p.id,
                    isSelected: module && module.length ? true : false
                };
            });




            this.setState({
                citiesinSelectState,
                verify: {
                    emailOperationalOtp: '',
                    emailOperational: true,
                    emailOperationalOtpSent: false,
                    emailOperationalOtpSentCount: 0,
                    emailAccountsOtp: '',
                    emailAccounts: true,
                    emailAccountsOtpSent: false,
                    emailAccountsOtpSentCount: 0,
                    mobileOperationalOtp: '',
                    mobileOperational: true,
                    mobileOperationalOtpSent: false,
                    mobileOperationalOtpSentCount: 0,
                    mobileAccountsOtp: '',
                    mobileAccounts: true,
                    mobileAccountsOtpSent: false,
                    mobileAccountsOtpSentCount: 0,
                },
                allModules
            });

        }



    }

    componentDidUpdate(prevProps) {


        if (this.props.permissionsMasterList !== prevProps.permissionsMasterList) {
            let permis = this.props.permissionsMasterList.map((p) => {
                let module = this.props.editOrganization && this.props.editOrganization.assignedModules.filter(m => m.id == p.id)
                return {
                    groupName: p.groupName,
                    id: p.id,
                    isSelected: module && module.length ? true : false
                };
            });

            this.setState({
                allModules: permis
            })

        }

    }


    onStateChange = (value, field, thisObj, formFields, formData, formValidations, formName) => {

        let data = JSON.parse(JSON.stringify(formData));
        data[field] = value;
        let fieldInfo = formFields.filter((f) => f.FieldName == field)
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${fieldInfo[0].Label} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }

        let selecteState = StatesAndDirstricts.filter((i) => i.State == value);
        let citiesinSelectState = selecteState[0].Districts.map((state) => {
            return { label: state, value: state }
        });




        thisObj.setState({
            [formName]: data,
            formValidations,
            citiesinSelectState
        });
    };



    isAdditionalValidOfStep3 = () => {

        let user = JSON.parse(JSON.stringify(this.state.user));
        let adminUserFormValidations = this.state.adminUserFormValidations

        // // password and confirm password
        let isValid = true;
        if (user.password != user.confirmPassword) {
            isValid = false;
            adminUserFormValidations.fields['confirmPassword'] = { ...adminUserFormValidations.fields['confirmPassword'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        // if (!isValid) {
        this.setState((prevState) => {
            return {
                adminUserFormValidations
            };
        });
        //}

        return isValid

        // if (true) {
        //     if (!(assignedSubjects && assignedSubjects.length)) {
        //         formValidations.fields['assignedSubjects'] = { ...formValidations.fields['assignedSubjects'], isValid: false, errorMsg: `Please assign subject` }
        //         isValid = false;
        //     } else if (!(assignedSubjects.every(i => i.assigned))) {
        //         isValid = false;
        //         formValidations.fields['assignedSubjects'] = { ...formValidations.fields['assignedSubjects'], isValid: false, errorMsg: `Please assign subject` }
        //     } else {
        //         formValidations.fields['assignedSubjects'] = { ...formValidations.fields['assignedSubjects'], isValid: true, errorMsg: `` }
        //     }

        // }


        // // if (!isValid) {
        // this.setState((prevState) => {
        //     return {
        //         formValidations
        //     };
        // });
        // //}

        // return isValid

    }


    isContactDetailValid = () => {
        let { verify } = this.state;
        if (verify.emailAccounts && verify.emailOperational && verify.mobileAccounts && verify.mobileOperational) {
            return true;
        } else {
            return false;
        }

    }

    isTenureValid = () => {
        let { org, formValidations } = this.state;
        let isValid = true;


        if (!(moment(org.tenureStartDate).isAfter(getServerTime()))) {
            formValidations.fields['tenureStartDate'] = {
                ...formValidations.fields['tenureStartDate'],
                isValid: false,
                errorMsg: `Tenure start date must greater than current date.`
            }
            isValid = false;
        }


        if (!(moment(org.tenureStartDate).isBefore(org.tenureEndDate))) {
            formValidations.fields['tenureEndDate'] = {
                ...formValidations.fields['tenureEndDate'],
                isValid: false,
                errorMsg: `Tenure end date must greater than start date.`
            }
            isValid = false;
        }

        this.setState({
            formValidations
        })

        return isValid;
    }

    isGSTDetailValid = () => {
        let { org } = this.state;
        org.gstNo = org.gstNo.trim();
        if (org.gstApplicable == 'yes') {
            if (org.gstNo != '' && org.gstNo.length == 15 && org.gstCertificate && org.gstCertificate != '') {
                return true;
            } else {
                return false;
            }
        } else if (org.gstApplicable == 'no') {
            return true;
        } else {
            return false;
        }
    }

    onSubmitClick = () => {

        const { currentStep, org, user, allModules, assignedCurriculums } = this.state;


        if (currentStep == 0) {
            const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.org));
            if (!formStatus.formValidations.isFormValid || !(this.isContactDetailValid()) || !(this.isGSTDetailValid() || !(this.isTenureValid()))) {
                this.setState({
                    isSubmitClick: true,
                    formValidations: formStatus.formValidations,
                });
            } else {
                this.setState({
                    currentStep: 1
                });
            }
        } else if (currentStep == 1) {



            let isValid = true;
            if (assignedCurriculums && assignedCurriculums.length && !(assignedCurriculums.every(i => i.assigned))) {
                isValid = false;

                this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please save or cancel curriculum which is in edit.', life: 3000 });
            }

            if (isValid) {
                this.setState({
                    currentStep: 2
                });
            }



        } else if (currentStep == 2) {


            let selectedModules = allModules?.filter(m => m.isSelected);

            if (selectedModules.length) {
                this.setState({
                    currentStep: 3
                });
            } else {
                this.toast.show({ severity: 'error', summary: 'Select Modules', detail: 'Please select atleast one module', life: 3000 });

            }





        }


        if (currentStep == 3) {



            const formStatus1 = isFormValid(adminUserFormFields, this.formFields2.formValidations, trimObj(user));

            let isCompleteFormValid = true;
            if (!formStatus1.formValidations.isFormValid) {
                this.setState({
                    adminUserFormValidations: formStatus1.formValidations,
                });
                isCompleteFormValid = false;
            } else if (!this.isAdditionalValidOfStep3()) {
                isCompleteFormValid = false;
            }


            if (isCompleteFormValid) {
                let thisObj = this;
                let selectedModules = allModules?.filter(m => m.isSelected);

                let userCopy = JSON.parse(JSON.stringify(user));
                delete userCopy.confirmPassword;


                let obj = {
                    ...org,
                    superAdmin: userCopy,
                    assignedModules: selectedModules,
                    assignedCurriculums: assignedCurriculums.map(a => { return { ...a.data, assigned: true } }),
                };



                obj = trimObj(obj);
                if (this.props.editOrganization) {
                    let url = `${baseUrlForLongRunningTask}/organization/update`;
                    this.setState({
                        isLoading: true
                    });
                    obj.superAdmin = undefined;
                    this.service.post(url, obj, true).then(res => {

                        if (res && res.status && res.res.status) {
                            this.setState({
                                isLoading: false,
                                isShowCreateSuccess: true
                            });
                            //   this.toast.show({ severity: 'success', summary: 'ORG updated', detail: 'Organization successfully updated', life: 3000 });

                            // thisObj.props.onCreateOrEditSuccess();

                        } else {
                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }

                    }).catch(e => {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                        console.log(e);
                    })


                } else {
                    let url = `${baseUrlForLongRunningTask}/organization/create`;
                    this.setState({
                        isLoading: true
                    })

                    this.service.post(url, obj, true).then(res => {

                        if (res && res.status && res.res.status) {
                            this.setState({
                                isLoading: false,
                                isShowCreateSuccess: true
                            });
                            //  this.toast.show({ severity: 'success', summary: 'ORG created', detail: 'Organization successfully created', life: 3000 });

                            //   thisObj.props.onCreateOrEditSuccess();
                        } else {
                            this.setState({
                                isLoading: false
                            });
                            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                        }

                    }).catch(e => {
                        this.setState({
                            isLoading: false
                        });
                        this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
                        console.log(e);
                    })



                }

            }







        }


    }


    sendOTPClick = (field, value, type) => {

        let { org } = this.state;

        if (!type && !isValidMobile(value)) {
            this.toast.show({ severity: 'error', summary: 'Invalid Mobile number', detail: 'Please enter correct phone number ', life: 3000 });
            return;
        }
        if (type == 'email' && !isValidEmail(value)) {
            this.toast.show({ severity: 'error', summary: 'Invalid Email Id', detail: 'Please enter the valid email Id', life: 3000 });
            return;

        }


        if (this.state.verify[field + 'OtpSentCount'] > 2) {
            this.toast.show({ severity: 'error', summary: 'You reached OTP send limit.', detail: 'you can\'t send more than 3 OTPs.', life: 3000 });

        } else {
            this.setState({
                isLoading: true
            });
            let url = ``;
            if (type == 'email') {
                url = `${baseUrlAdmin}/otp/mail/send/${org[field]}`;
            } else {
                url = `${baseUrlAdmin}/otp/send/+91${org[field]}`;
            }

            this.service.post(url, {}, true).then(res => {

                if (res && res.status && res.res.success) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            verify: {
                                ...prevState.verify,
                                [field + 'OtpSent']: true,
                                [field + 'OtpSentCount']: prevState.verify[field + 'OtpSentCount'] += 1,
                            }
                        };
                    });
                    this.toast.show({ severity: 'success', summary: 'OTP Sent successfully', detail: 'OTP sent. Please verify', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })
        }
        //     } else {
        //     this.toast.show({ severity: 'error', summary: 'Invalid Mobile number', detail: 'Please enter correct phone number ', life: 3000 });
        // }
    }


    validateOTP = (field, type) => {

        let { org, verify } = this.state;
        let verifyField = org[field];
        // mobileAccountsOTP: '',
        // mobileAccounts: false,
        // mobileAccountsOtpSent: false,
        // mobileAccountsOtpSentCount: 0,

        let otp = verify[field + 'Otp'];




        if (otp.length == 4) {
            this.setState({
                isLoading: true
            });

            let url = '';

            if (type == 'email') {
                url = `${baseUrlAdmin}/otp/mail/verifyemail/${verifyField}/${otp}`;
            } else {
                url = `${baseUrlAdmin}/otp/verifyphone/+91${verifyField}/${otp}`;
            }


            this.service.post(url, {}, true).then(res => {

                if (res && res.status && res.res.status) {
                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            verify: {
                                ...prevState.verify,
                                [field]: true
                            }
                        };
                    });
                    this.toast.show({ severity: 'success', summary: 'OTP Verified', detail: 'OTP successfully verified.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage ? res.errorMsg : res.res && res.res.message, life: 3000 });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                console.log(e);
            })


        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid OTP', detail: 'OTP must be 4 characters and numbers', life: 3000 });
        };
    }




    onChangeOTP = (field, value) => {

        if (value.length > 4 || isNaN(value)) {
            this.toast.show({ severity: 'error', summary: 'Invalid OTP', detail: 'OTP must be 4 characters and numbers', life: 3000 });
        } else {

            this.setState((prevState) => {
                return {
                    isLoading: false,
                    verify: {
                        ...prevState.verify,
                        [field + 'Otp']: value,
                    }
                };
            });
        }
    }


    onTenureStartChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {
        let data = JSON.parse(JSON.stringify(formData));

        if ((moment(value).isAfter(getServerTime()))) {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Tenure start date must be greater than current date.`
            }
        }

        if (!(moment(value).isBefore(data.tenureEndDate))) {
            data.tenureEndDate = '';
            formValidations.fields['tenureEndDate'] = {
                ...formValidations.fields['tenureEndDate'],
                isValid: false,
                errorMsg: `Please select tenure end date, start date changed.`
            }
        }

        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    onTenureEndChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName) => {

        let data = JSON.parse(JSON.stringify(formData));
        if (moment(value).isAfter(data.tenureStartDate)) {
            data[fieldName] = new Date(value).toString();
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: true,
                errorMsg: ``
            }
        } else {
            formValidations.fields[fieldName] = {
                ...formValidations.fields[fieldName],
                isValid: false,
                errorMsg: `Tenure End date must be greater than start date.`
            }
        }
        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations
        });
    }

    getURL = (value, fieldName) => {
        return `https://${value}${fieldName == 'subDomain' ? '.macademy.in' : ''}`;

    }

    onURLChange = (value, fieldName, thisObj, formFields, formData, formValidations, formName, formValidationName, additionalUpdOBJ) => {
        let data = JSON.parse(JSON.stringify(formData));

        let url = this.getURL(value, fieldName)
        let isValid = isValidUrl(url) && value.length > 3;


        data[fieldName] = value;
        formValidations.fields[fieldName] = {
            ...formValidations.fields[fieldName],
            isValid: isValid,
            errorMsg: isValid ? '' : 'Invalid URL'
        }

        thisObj.setState({
            [formName]: data,
            [formValidationName]: formValidations,
            ...additionalUpdOBJ
        });
    }


    _onProgress = (filesInfo) => {
        // let files = this.state.uploadedFilesInfo;
        // files = files.map((file) => {
        //     if (file.name == filesInfo.name) {
        //         file = filesInfo;
        //     }
        //     return file;
        // })

        // 
        // this.setState((prevState) => {
        //     return {
        //         org: {
        //             ...prevState.org,
        //             logoRectangle: filesInfo
        //         }
        //     }
        // });
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    _afterFilesUploaded = (files) => {

    }


    addAttachment = (fieldName, fileName, data) => {
        let org = {
            ...this.state.org,
            [fieldName]: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,

            }
        }
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, org);


        this.setState((prevState) => {
            return {
                org,
                formValidations
            }
        });
    }


    _onLogoRectSuccess = (fileName, data) => {

        this.addAttachment('logoRectangle', fileName, data);
        return
        let org = {
            ...this.state.org,
            logoRectangle: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,

            }
        }
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'logoRectangle'), this.state.formValidations, org);


        this.setState((prevState) => {
            return {
                org,
                formValidations
            }
        });

    }

    _onLogoSquareSuccess = (fileName, data) => {

        this.addAttachment('logoSquare', fileName, data);
        return;

        let org = {
            ...this.state.org,
            logoSquare: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,

            }
        }
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'logoSquare'), this.state.formValidations, org);


        this.setState((prevState) => {
            return {
                org,
                formValidations
            }
        });
    }

    _onAgreementSuccess = (fileName, data) => {

        this.addAttachment('agreement', fileName, data);
        return;
        let org = {
            ...this.state.org,
            agreement: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,

            }
        }
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'agreement'), this.state.formValidations, org);


        this.setState((prevState) => {
            return {
                org,
                formValidations
            }
        });


    }

    _onGstCertSuccess = (fileName, data) => {
        this.addAttachment('gstCertificate', fileName, data);
        return;
        let org = {
            ...this.state.org,
            gstCertificate: {
                fileName,
                storageBucket: data.Bucket,
                storageKey: data.Key,
                storageLocation: data.Location,

            }
        }
        let formValidations = isFieldValid(formFields.find(fm => fm.FieldName == 'gstCertificate'), this.state.formValidations, org);


        this.setState((prevState) => {
            return {
                org,
                formValidations
            }
        });

    }


    onLocationTextChanged = (value) => {

        if (window.google != undefined) {

            // let map = new window.google.maps.Map(document.getElementById("map"), {
            //     zoom: 8,
            //     center: { lat: -34.397, lng: 150.644 },
            //     mapTypeControl: false,
            // });

            let geocoder = new window.google.maps.Geocoder();

            let filter = { address: 'meluha international school' };
            geocoder
                .geocode(filter)
                .then((result) => {
                    const { results } = result;

                    //  console.log(results)
                    // map.setCenter(results[0].geometry.location);
                    // marker.setPosition(results[0].geometry.location);
                    // marker.setMap(map);
                    // responseDiv.style.display = "block";
                    // response.innerText = JSON.stringify(result, null, 2);
                    return results;
                })
                .catch((e) => {
                    alert("Geocode was not successful for the following reason: " + e);
                });
        }

    }


    searchLocation = () => {

    }

    // verifyOTP = (field, value) => {

    //     if (isValidMobile(value)) {
    //         // this.toast.show({ severity: 'success', summary: 'OTP Sent successfully', detail: 'OTP sent to mobile.Please verify', life: 3000 });
    //         // return

    //         if (this.state.verify[field + 'OtpSentCount'] > 2) {
    //             this.toast.show({ severity: 'error', summary: 'You reached OTP send limit.', detail: 'you can\'t send more than 3 OTPs.', life: 3000 });

    //         } else {
    //             this.setState({
    //                 isLoading: true
    //             });
    //             const url = `${baseUrlAdmin}/otp/send/+91${this.state.org.mobileOperational}`;
    //             this.service.post(url, {}, false).then(res => {
    //                 
    //                 if (res && res.status && res.res.success) {
    //                     this.setState((prevState) => {
    //                         return {
    //                             isLoading: false,
    //                             verify: {
    //                                 ...prevState.verify,
    //                                 [field + 'OtpSent']: true,
    //                                 [field + 'OtpSentCount']: prevState.verify[field + 'OtpSentCount'] += 1,
    //                             }
    //                         };
    //                     });
    //                     this.toast.show({ severity: 'success', summary: 'OTP Sent successfully', detail: 'OTP sent to mobile.Please verify', life: 3000 });
    //                 } else {
    //                     this.setState({
    //                         isLoading: false
    //                     });
    //                     this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
    //                 }
    //                 console.log(res);

    //             }).catch(e => {
    //                 this.setState({
    //                     isLoading: false
    //                 });
    //                 console.log(e);
    //             })
    //         }
    //     } else {
    //         this.toast.show({ severity: 'error', summary: 'Invalid Mobile number', detail: 'Please enter correct phone number ', life: 3000 });
    //     }
    // }


    onAssignClick = (curriculum) => {
        let assignedCurriculums = this.state.assignedCurriculums.map((item) => {
            if (item.id == curriculum.id) {
                return curriculum;
            } else {
                return item;
            }
        });

        this.setState((prevState) => {
            return {
                assignedCurriculums: assignedCurriculums

            }
        });
    }

    addMore = () => {

        if (this.state.assignedCurriculums.every((s) => s.assigned === true)) {

            this.setState((prevState) => {
                return {
                    assignedCurriculums: [...prevState.assignedCurriculums, { id: prevState.curriculumTempIndex + 1, assigned: false }],
                    curriculumTempIndex: prevState.curriculumTempIndex + 1
                }
            }
            );
        } else {
            this.toast.show({ severity: 'warn', summary: 'Warning', detail: 'Please assign subject to add more', life: 3000 });

        }
    }



    removeTempCurriculum = (id) => {

        if (this.state.assignedCurriculums.length) {
            let assignedCurriculums = this.state.assignedCurriculums.filter(item => item.id != id);
            this.setState((prevState) => {
                return {
                    assignedCurriculums
                }
            });
        }
    }


    handleCheckBoxChange = (mId) => (event) => {

        let allModules = this.state.allModules.map(m => {
            if (mId == m.id) {
                m.isSelected = event.target.checked;
            }
            return m;
        });
        this.setState({
            allModules
        })
    };

    updateGSTFields = (isApplicable) => {
        if (isApplicable == 'yes') {
            formFields = formFields.map(field => {
                if (field.FieldName == 'gstCertificate' || field.FieldName == 'gstNo') {
                    field.Required = true;
                }
                return field;
            });

            this.formFields = getFormFields(formFields, this.state.org);


            this.setState({
                formValidations: this.formFields.formValidations
            })

        } else {
            formFields = formFields.map(field => {
                if (field.FieldName == 'gstCertificate' || field.FieldName == 'gstNo') {
                    field.Required = undefined;
                }
                return field;
            });

            this.formFields = getFormFields(formFields, this.state.org);


            this.setState({
                formValidations: this.formFields.formValidations
            })
        }
    }

    checkIsSubDomainAvail = (subDomain) => {
        let url = `${baseUrlAdmin}/organization/domain/isavailable/${subDomain}`;


        if (subDomain && subDomain != '' && subDomain.length > 3) {
            this.setState({
                isDomainChecked: true,
                isLoading: true
            });

            this.service.get(url, true).then(res => {

                if (res && res.res && res.res.isDomainAvaliable) {
                    this.setState({
                        isDomainAvaliable: true,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isDomainAvaliable: false,
                        isLoading: false
                    });
                }

            }).catch(e => {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured.', detail: e.message, life: 3000 });
                console.log(e);
            })

        } else {
            this.toast.show({ severity: 'warn', summary: 'Invalid Sub domain', detail: 'Please enter sub domain more than 3 chars.', life: 3000 });
        }
    }

    onBackClick = () => {
        if (this.state.currentStep == 0) {
            this.props.onCreateCancel();
        } else {
            this.setState((prevState) => {
                return { currentStep: prevState.currentStep - 1 }
            });
        }

    }

    render() {
        const { org, formValidations, verify, currentStep, user, isSubmitClick, adminUserFormValidations } = this.state;
        return (<>
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
            <div className="ma-box-style2 create-org-w">
                <span className='create-org-title'>{this.props.editOrganization ? 'Edit' : 'Create'} Organization</span>
                <Steps
                    className='org-steps'
                    model={items} activeIndex={currentStep}
                    readOnly={false}
                />

                <div className='ma-clearFix'>

                </div>
                {currentStep == 0 && <>



                    <p className='craete-org-subtitle'>Organization Details</p>
                    <hr className="ma-hr-light" />
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Organization Name (Registered Name as ROC)<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.ORG_NAME} id="co4">
                                <InputText value={org.name} onChange={(e) => { onTextChange(e.target.value, 'name', this, formFields, org, formValidations, 'org', 'formValidations') }} className='p-inputtext-style1 ma-w100p' />
                            </InputTextB>
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Organization Title<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.ORG_TITLE} id="co5">
                                <InputText value={org.title} onChange={(e) => { onTextChange(e.target.value, 'title', this, formFields, org, formValidations, 'org', 'formValidations') }} className='p-inputtext-style1 ma-w100p' />
                            </InputTextB>
                            {formValidations && !formValidations.fields['title'].isValid && <p className="p-error">{formValidations.fields['title'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Communication Address<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.ORG_ADDRESS} id="co6">
                                <InputTextarea
                                    value={org.address}
                                    onChange={(e) => {
                                        onTextChange(e.target.value, 'address', this, formFields, org, formValidations, 'org', 'formValidations')
                                    }}
                                    rows={5} className='p-inputtext-style1 ma-w100p' />
                            </InputTextB>
                            {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>}


                        </div>

                        <div className="p-col-12 p-md-6 ma-pr0">
                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Year of ESTD<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_ESTD} id="co7">
                                        <Dropdown value={org.yearOfESTD}
                                            className='ma-w140 ma-w100p'
                                            options={getESTDYears()}
                                            onChange={(e) => onDropDownChange(e.value, 'yearOfESTD', this, formFields, org, formValidations, 'org', 'formValidations')}
                                            placeholder="--Select ESTD--" />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['yearOfESTD'].isValid && <p className="p-error">{formValidations.fields['yearOfESTD'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Country<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_COUNTRY} id="co8">
                                        <Dropdown value={org.country}
                                            className='ma-w140 ma-w100p'
                                            options={geCountries()}
                                            onChange={(e) => onDropDownChange(e.value, 'country', this, formFields, org, formValidations, 'org', 'formValidations')}
                                            placeholder="--Select Country--" />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['country'].isValid && <p className="p-error">{formValidations.fields['country'].errorMsg}</p>}
                                </div>
                            </div>

                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>State<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_STATE} id="co9">
                                        <Dropdown value={org.state}
                                            className='ma-w140 ma-w100p'
                                            options={States.map((state) => {
                                                return { label: state, value: state }
                                            })}
                                            onChange={(e) => this.onStateChange(e.value, 'state', this, formFields, org, formValidations, 'org')}
                                            placeholder="--Select State--" />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['state'].isValid && <p className="p-error">{formValidations.fields['state'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>City<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_CITY} id="co10">
                                        <Dropdown value={org.city}
                                            className='ma-w140 ma-w100p'
                                            options={this.state.citiesinSelectState}
                                            onChange={(e) => onDropDownChange(e.value, 'city', this, formFields, org, formValidations, 'org', 'formValidations')}
                                            placeholder="--Select State--" />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['city'].isValid && <p className="p-error">{formValidations.fields['city'].errorMsg}</p>}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6">
                            {/* <p className='ma-label-s2'>Communication Address<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.ORG_ADDRESS} id="co6">
                                <AutoComplete
                                    className='p-inputtext-style1 ma-w100p'
                                    value={this.state.locationText}
                                    suggestions={this.state.locations}
                                    //completeMethod={this.onLocationTextChanged}
                                    onChange={(e) => this.onLocationTextChanged(e.value)} />
                            </InputTextB>
                            {formValidations && !formValidations.fields['address'].isValid && <p className="p-error">{formValidations.fields['address'].errorMsg}</p>} */}

                        </div>

                        <div className="p-col-12 p-md-6 ma-pr0">
                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>PinCode<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_PINCODE} id="co11">
                                        <InputText className='p-inputtext-style1 ma-w100p'
                                            value={org.pincode}
                                            onChange={(e) => {
                                                onNumberChange(e.target.value, 'pincode', this, formFields, org, formValidations, 'org', 'formValidations')
                                            }}
                                        />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['pincode'].isValid && <p className="p-error">{formValidations.fields['pincode'].errorMsg}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className='craete-org-subtitle'>Contact Details</p>
                    <hr className="ma-hr-light" />
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6 ma-pr0">
                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Mobile Number-Operational<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_MOBILE_NUMBER_OPERATIONAL} id="co12">
                                        <InputText2 left={'+91'}
                                            value={org.mobileOperational}
                                            onChange={(e) => {
                                                onNumberChange(e.target.value, 'mobileOperational', this, formFields, org, formValidations, 'org', 'formValidations')
                                            }}
                                            actionText={verify['mobileOperationalOtpSentCount'] ? 'Resend  OTP' : 'Send OTP'}
                                            onActionClick={() => { this.sendOTPClick('mobileOperational', org.mobileOperational) }}
                                            otpVerified={verify['mobileOperational']}
                                            EditActionText='Edit'
                                            optSent={verify['mobileOperationalOtpSent']}
                                        />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['mobileOperational'].isValid && <p className="p-error">{formValidations.fields['mobileOperational'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Enter OTP<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_OTP} id="co13">
                                        <ValidateOTP
                                            onChange={(e) => { this.onChangeOTP('mobileOperational', e.target.value) }}
                                            value={verify['mobileOperationalOtp']}
                                            id={'accotp'}
                                            verifiedText={'OTP Verified'}
                                            rightText="Validate OTP"
                                            onActionClick={() => { this.validateOTP('mobileOperational') }}
                                            otpVerified={verify['mobileOperational']}
                                        />
                                    </InputTextB>
                                    {isSubmitClick && verify && !verify['mobileOperational'] && <p className="p-error">Verify OTP</p>}
                                </div>

                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 ma-pr0">

                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Mobile Number-Accounts<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_MOBILE_NUMBER_ACCOUNTS} id="co12">
                                        <InputText2 left={'+91'}
                                            onChange={(e) => {
                                                onNumberChange(e.target.value, 'mobileAccounts', this, formFields, org, formValidations, 'org', 'formValidations')
                                            }}
                                            actionText={verify['mobileAccountsOtpSentCount'] ? 'Resend  OTP' : 'Send OTP'}
                                            otpVerified={verify['mobileAccounts']}
                                            optSent={verify['mobileAccountsOtpSent']}
                                            onActionClick={() => { this.sendOTPClick('mobileAccounts', org.mobileAccounts) }}
                                            value={org.mobileAccounts}

                                        />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['mobileAccounts'].isValid && <p className="p-error">{formValidations.fields['mobileAccounts'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Enter OTP<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_OTP} id="co13">
                                        <ValidateOTP
                                            onChange={(e) => { this.onChangeOTP('mobileAccounts', e.target.value) }}
                                            value={verify['mobileAccountsOtp']}
                                            id={'accotp'}
                                            verifiedText={'OTP Verified'}
                                            onActionClick={() => { this.validateOTP('mobileAccounts') }}
                                            otpVerified={verify['mobileAccounts']}
                                            rightText="Validate OTP" />
                                    </InputTextB>
                                    {isSubmitClick && verify && !verify['mobileAccounts'] && <p className="p-error">Verify OTP</p>}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6 ma-pr0">

                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6  ma-pr0">
                                    <p className='ma-label-s2'>Email ID-Operational<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_EMAIL_ID_OPERATIONAL} id="co12">
                                        <EMailText
                                            onChange={(e) => { onEmailChange(e.target.value, 'emailOperational', this, formFields, org, formValidations, 'org', 'formValidations') }}
                                            value={org.emailOperational}
                                            otpVerified={verify['emailOperational']}
                                            optSent={verify['emailOperationalOtpSent']}
                                            onActionClick={() => { this.sendOTPClick('emailOperational', org.emailOperational, 'email') }}
                                            rightText={verify['emailOperationalOtpSentCount'] ? 'Resend  OTP' : 'Send OTP'} />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['emailOperational'].isValid && <p className="p-error">{formValidations.fields['emailOperational'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6  ma-pr0">
                                    <p className='ma-label-s2'>Enter OTP<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_OTP} id="co13">
                                        {/* <ValidateOTP
                                            verifiedText={'OTP Verified'}
                                            onChange={(e) => { this.onChangeOTP('emailOperational', e.target.value) }}
                                            value={verify['emailOperationalOtp']}
                                            rightText="Validate OTP" /> */}
                                        <ValidateOTP
                                            onChange={(e) => { this.onChangeOTP('emailOperational', e.target.value) }}
                                            value={verify['emailOperationalOtp']}
                                            id={'accotpd'}
                                            verifiedText={'OTP Verified'}
                                            onActionClick={() => { this.validateOTP('emailOperational', 'email') }}
                                            otpVerified={verify['emailOperational']}
                                            rightText="Validate OTP" />
                                    </InputTextB>
                                    {isSubmitClick && verify && !verify['emailOperational'] && <p className="p-error">Verify OTP</p>}
                                </div>
                            </div>

                        </div>
                        <div className="p-col-12 p-md-6 ma-pr0">

                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Email ID-Accounts<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_EMAIL_ID_ACCOUNTS} id="co13">
                                        <EMailText
                                            onChange={(e) => { onEmailChange(e.target.value, 'emailAccounts', this, formFields, org, formValidations, 'org', 'formValidations') }}
                                            value={org.emailAccounts}
                                            otpVerified={verify['emailAccounts']}
                                            optSent={verify['emailAccountsOtpSent']}
                                            onActionClick={() => { this.sendOTPClick('emailAccounts', org.emailAccounts, 'email') }}
                                            rightText={verify['emailAccountsOtpSentCount'] ? 'Resend  OTP' : 'Send OTP'} />
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['emailAccounts'].isValid && <p className="p-error">{formValidations.fields['emailAccounts'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Enter OTP<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_OTP} id="co13">
                                        <ValidateOTP
                                            onChange={(e) => { this.onChangeOTP('emailAccounts', e.target.value) }}
                                            value={verify['emailAccountsOtp']}
                                            id={'accotpd'}
                                            verifiedText={'OTP Verified'}
                                            onActionClick={() => { this.validateOTP('emailAccounts', 'email') }}
                                            otpVerified={verify['emailAccounts']}
                                            rightText="Validate OTP" />
                                    </InputTextB>
                                    {isSubmitClick && verify && !verify['emailAccounts'] && <p className="p-error">Verify OTP</p>}
                                </div>
                            </div>

                        </div>

                    </div>

                    <p className='craete-org-subtitle'>Website Details</p>
                    <hr className="ma-hr-light" />
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Website</p>
                            <InputTextB info={FIELDS_INFO.ORG_WEBSITE} id="co20">
                                <div style={{ display: 'flex' }} className='custom-input2 ma-w100p'>
                                    <span className='left'>https://</span>
                                    <input type="text"
                                        value={org.website}
                                        onChange={(e) => this.onURLChange(e.target.value, 'website', this, formFields, org, formValidations, 'org', 'formValidations')}
                                        className='input-text ma-w100p' />
                                </div>
                            </InputTextB>
                            {formValidations && !formValidations.fields['website'].isValid && <p className="p-error">{formValidations.fields['website'].errorMsg}</p>}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>SMS Client website</p>
                            <InputTextB info={FIELDS_INFO.ORG_SMS_CLIENT_WEBSITE} id="co21">
                                <div style={{ display: 'flex' }} className='custom-input2 ma-w100p'>
                                    <span className='left'>https://</span>
                                    <input type="text"
                                        value={org.smsClientWebsite}
                                        onChange={(e) => this.onURLChange(e.target.value, 'smsClientWebsite', this, formFields, org, formValidations, 'org', 'formValidations')}
                                        className='input-text ma-w90p' />
                                </div>
                            </InputTextB>
                            {formValidations && !formValidations.fields['smsClientWebsite'].isValid && <p className="p-error">{formValidations.fields['smsClientWebsite'].errorMsg}</p>}
                        </div>
                    </div>
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Email Client website</p>
                            <InputTextB info={FIELDS_INFO.ORG_EMAIL_CLIENT_WEBSITE} id="co22">
                                <div style={{ display: 'flex' }} className='custom-input2 ma-w100p'>
                                    <span className='left'>https://</span>
                                    <input type="text"
                                        value={org.emailClientWebsite}
                                        onChange={(e) => this.onURLChange(e.target.value, 'emailClientWebsite', this, formFields, org, formValidations, 'org', 'formValidations')}
                                        className='input-text ma-w90p' />
                                </div>
                            </InputTextB>
                            {formValidations && !formValidations.fields['emailClientWebsite'].isValid && <p className="p-error">{formValidations.fields['emailClientWebsite'].errorMsg}</p>}
                        </div>
                        <div className="p-col-12 p-md-6">
                            <p className='ma-label-s2'>Sub Domain<span className='ma-required'>*</span></p>
                            <InputTextB info={FIELDS_INFO.ORG_SUBDOMAIN} id="co23">
                                <div style={{ display: 'flex' }} className='custom-input2 ma-w100p'>
                                    <span className='left'>https://</span>
                                    <input type="text"
                                        disabled={this.props.editOrganization}
                                        onChange={(e) => {
                                            this.onURLChange(e.target.value, 'subDomain', this, formFields, org, formValidations, 'org', 'formValidations', { isDomainChecked: false, isDomainAvaliable: false })
                                        }}
                                        value={org.subDomain}
                                        className='input-text ma-w100p' />
                                </div>
                                <span className='domain-label'>.macademy.in</span>
                                {!this.props.editOrganization && <>
                                    <Button label={`Check`} className='ma-ml10 p-button-outlined' onClick={() => this.checkIsSubDomainAvail(org.subDomain)} />
                                </>}
                            </InputTextB>
                            {!this.props.editOrganization && <>
                                {this.state.isDomainChecked && (this.state.isDomainAvaliable ? <span className='ma-success-msg'>Available</span> : <span className='ma-error-msg'>Not available</span>)}
                            </>}

                            {formValidations && !formValidations.fields['subDomain'].isValid && <p className="p-error">{formValidations.fields['subDomain'].errorMsg}</p>}
                        </div>
                    </div>


                    <p className='craete-org-subtitle'>Documents</p>
                    <hr className="ma-hr-light" />
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6  ma-pr0">
                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6  ma-pr0">
                                    <p className='ma-label-s2'>Logo -Rectangular<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_LOGO_RECTANGUALAR} id="co24">
                                        <div className='custom-input2 file-attach'>
                                            <FileUpload id={"logorect"}
                                                multiple={false}
                                                onProgress={this._onProgress}
                                                onSucess={this._onLogoRectSuccess}
                                                //  Reset={this.state.Reset}
                                                maxFileSize={307200}
                                                onError={this._onFilsUploadError}
                                                accept={'images'}
                                                title={'Images'}
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                            </FileUpload>
                                            <div style={{ height: '40px' }} className='logo-rect-text'>
                                                {
                                                    (org.logoRectangle && org.logoRectangle.fileName) ? org.logoRectangle.fileName : 'No file choosen'
                                                }
                                            </div>
                                            {
                                                org.logoRectangle && org.logoRectangle.storageLocation && <img src={org.logoRectangle.storageLocation}
                                                    className="rect-logo" />
                                            }
                                        </div>
                                    </InputTextB>
                                    <p className='field-alert'>(*Only JPEG/PNG formats are allowed with size below 300KB)</p>
                                    {formValidations && !formValidations.fields['logoRectangle'].isValid && <p className="p-error">{formValidations.fields['logoRectangle'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Logo - Square<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_LOGO_SQUARE} id="co25">
                                        <div className='custom-input2 file-attach'>
                                            <FileUpload id={"logo-square"}
                                                multiple={false}
                                                onProgress={this._onProgress}
                                                onSucess={this._onLogoSquareSuccess}
                                                Reset={this.state.Reset}
                                                maxFileSize={307200}
                                                onError={this._onFilsUploadError}
                                                accept={'images'}
                                                title={'Images'}
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                                {/* <Button label={`Choose file`} className='p-button-outlined ma-right' onClick={(e) => { e.preventDefault() }} /> */}
                                                {/* <img src="./images/Voice_recorder.svg" className="ma-pointer li-img-icon" /> */}
                                            </FileUpload>
                                            <div style={{ height: '40px' }} className='logo-rect-text'>
                                                {
                                                    (org.logoSquare && org.logoSquare.fileName) ? org.logoSquare.fileName : 'No file choosen'
                                                }
                                            </div>
                                            {
                                                org.logoSquare && org.logoSquare.storageLocation && <img src={org.logoSquare.storageLocation}
                                                    className="sqaure-logo" />
                                            }
                                        </div>
                                    </InputTextB>
                                    <p className='field-alert'>(*Only JPEG/PNG formats are allowed with size below 300KB)</p>
                                    {formValidations && !formValidations.fields['logoSquare'].isValid && <p className="p-error">{formValidations.fields['logoSquare'].errorMsg}</p>}
                                </div>
                            </div>

                        </div>
                        <div className="p-col-12 p-md-6 ma-pr0">
                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <div className="p-grid ma-w100p ma-mt10" >
                                        <div className="p-col-12 p-md-5 ">
                                            <p className='ma-label-s2'>GST Applicable<span className='ma-required'>*</span></p>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            {/* <InputTextB info={FIELDS_INFO.ORG_GST_CERTIFICATE} id="co26"> */}
                                            <RadioButton className="radio-inline" value='yes' inputid="coyes" name="gstApplicable"
                                                onChange={(e) => {
                                                    onChoiceChange(e.value, 'gstApplicable', this, formFields, org, formValidations, 'org', 'formValidations');
                                                    this.updateGSTFields(e.value)
                                                }}
                                                checked={org.gstApplicable === 'yes'} />

                                            <label className='ma-label-s1' htmlFor="yes">Yes</label>
                                            <RadioButton className="radio-inline ma-ml10" value='no' inputid="cono" name="gstApplicable"
                                                onChange={(e) => {
                                                    onChoiceChange(e.value, 'gstApplicable', this, formFields, org, formValidations, 'org', 'formValidations')
                                                    this.updateGSTFields(e.value)
                                                }}
                                                checked={org.gstApplicable === 'no'} />
                                            <label className='ma-label-s1' htmlFor="no">No</label>

                                            {/* </InputTextB> */}
                                            {formValidations && !formValidations.fields['gstApplicable'].isValid && <p className="p-error">{formValidations.fields['gstApplicable'].errorMsg}</p>}
                                        </div>
                                        <div className='ma-w100p'>
                                            <p className='ma-label-s2'>If yes, GST No<span className='ma-required'>*</span></p>
                                            <InputTextB info={FIELDS_INFO.ORG_GSTNO} id="co27">
                                                <InputText value={org.gstNo.toUpperCase()} disabled={org.gstApplicable === 'no'} onChange={(e) => { onTextChange(e.target.value, 'gstNo', this, formFields, org, formValidations, 'org', 'formValidations') }} className='p-inputtext-style1 ma-w100p' />
                                            </InputTextB>
                                            {formValidations && !formValidations.fields['gstNo'].isValid && <p className="p-error">{formValidations.fields['gstNo'].errorMsg}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Upload GST Certificate {org.gstApplicable === 'yes' && <span className='ma-required'>*</span>}</p>
                                    <InputTextB info={FIELDS_INFO.ORG_GST_CERTIFICATE} id="co28">
                                        <div className='custom-input2 file-attach'>
                                            <FileUpload id={"gstCertificate"}
                                                multiple={false}
                                                //// onProgress={this._onProgress}
                                                onSucess={this._onGstCertSuccess}
                                                //  Reset={this.state.Reset}
                                                maxFileSize={5242880}
                                                onError={this._onFilsUploadError}
                                                disabled={org.gstApplicable === 'no'}
                                                accept={'pdfs'}
                                                title={'PDf'}
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <span className={`outlined-s1 ma-right ma-pointer ${org.gstApplicable === 'no' ? 'disabled-style' : ''}`}>Choose file</span>
                                                {/* <Button label={`Choose file`} className='p-button-outlined ma-right' onClick={(e) => { e.preventDefault() }} /> */}
                                                {/* <img src="./images/Voice_recorder.svg" className="ma-pointer li-img-icon" /> */}
                                            </FileUpload>
                                            <div className='space logo-rect-text'>
                                                {
                                                    (org.gstCertificate && org.gstCertificate.fileName) ? '' : 'No file choosen'
                                                }
                                            </div>
                                            {org.gstCertificate && org.gstCertificate.storageLocation && <> <a className='attach-link' href={org.gstCertificate.storageLocation} target='_blank'>{org.gstCertificate.fileName}</a>
                                                <i className='pi pi-download down-attache' onClick={() => { handleDownload(org.gstCertificate.storageLocation, org.gstCertificate.fileName) }} ></i>
                                            </>}

                                        </div>
                                    </InputTextB>
                                    <p className='field-alert'>(*Only PDF formats are allowed with size below 5MB)</p>
                                    {formValidations && !formValidations.fields['gstCertificate'].isValid && <p className="p-error">{formValidations.fields['gstCertificate'].errorMsg}</p>}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="p-grid ma-w100p" >
                        <div className="p-col-12 p-md-6 ma-pr0 ">
                            <div className="p-grid ma-w100p" >
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Agreement Tenure<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_AGREEMENT_TENURE_STARTDATE} id="co29">
                                        <Calendar value={new Date(org.tenureStartDate)}
                                            showIcon={true}
                                            placeholder='Start Date'
                                            id='tenurestart'
                                            onChange={(e) => this.onTenureStartChange(e.value, 'tenureStartDate', this, formFields, org, formValidations, 'org', 'formValidations')}
                                            className='ma-w100p'
                                        ></Calendar>
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['tenureStartDate'].isValid && <p className="p-error">{formValidations.fields['tenureStartDate'].errorMsg}</p>}
                                    <p></p>
                                    <InputTextB info={FIELDS_INFO.ORG_AGREEMENT_TENURE_ENDDATE} id="co30">

                                        <Calendar value={new Date(org.tenureEndDate)}
                                            id='tenureend'
                                            placeholder='End Date'
                                            showIcon={true}
                                            disabled={org.tenureStartDate == ''}
                                            onChange={(e) => this.onTenureEndChange(e.value, 'tenureEndDate', this, formFields, org, formValidations, 'org', 'formValidations')}
                                            className='ma-w100p '
                                        ></Calendar>
                                    </InputTextB>
                                    {formValidations && !formValidations.fields['tenureEndDate'].isValid && <p className="p-error">{formValidations.fields['tenureEndDate'].errorMsg}</p>}
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-6 ma-pr0">
                                    <p className='ma-label-s2'>Upload agreement<span className='ma-required'>*</span></p>
                                    <InputTextB info={FIELDS_INFO.ORG_AGREEMENT} id="co31">
                                        <div className='custom-input2 file-attach'>
                                            <FileUpload id={"agreement"}

                                                multiple={false}
                                                //// onProgress={this._onProgress}
                                                onSucess={this._onAgreementSuccess}
                                                //  Reset={this.state.Reset}
                                                maxFileSize={5242880}
                                                //   onError={this._onFilsUploadError}
                                                accept={'pdfs'}
                                                title={'Pdfs'}
                                                afterFilesUploaded={this._afterFilesUploaded}
                                            >
                                                <span className={`outlined-s1 ma-right ma-pointer`}>Choose file</span>
                                                {/* <Button label={`Choose file`} className='p-button-outlined ma-right' onClick={(e) => { e.preventDefault() }} /> */}
                                                {/* <img src="./images/Voice_recorder.svg" className="ma-pointer li-img-icon" /> */}
                                            </FileUpload>
                                            <div className='space logo-rect-text'>
                                                {
                                                    (org.agreement && org.agreement.fileName) ? '' : 'No file choosen'
                                                }
                                            </div>
                                            {org.agreement && org.agreement.storageLocation && <> <a className='attach-link' href={org.agreement.storageLocation} target='_blank'>{org.agreement?.fileName}</a>
                                                <i className='pi pi-download down-attache' onClick={() => { handleDownload(org.agreement.storageLocation, org.agreement.fileName) }}></i>
                                            </>}

                                        </div>
                                    </InputTextB>
                                    <p className='field-alert'>(*Only PDF formats are allowed with size below 5MB)</p>
                                    {formValidations && !formValidations.fields['agreement'].isValid && <p className="p-error">{formValidations.fields['agreement'].errorMsg}</p>}
                                </div>
                            </div>

                        </div>
                        <div className="p-col-12 p-md-6">

                        </div>
                    </div>
                    {/* <span className="p-input-icon-right">
                    <i className="pi pi-spin pi-spinner" />
                    <InputText className='p-inputtext-style1 ma-w100p' />
                </span> */}

                </>}
                {
                    currentStep == 1 && <>
                        <div className="p-col-10 p-md-10">
                            <h2 className='assign-master'>Assign Master Curriculum</h2>
                            {
                                this.state.assignedCurriculums && this.state.assignedCurriculums && this.state.assignedCurriculums.map((temp) => {
                                    return <div key={temp.id} >
                                        <AssignCurriculums data={temp.data}
                                            assignedCurriculums={this.state.assignedCurriculums}
                                            key={temp.id}
                                            id={temp.id}
                                            onAssignClick={this.onAssignClick}
                                            removeTempCurriculum={this.removeTempCurriculum} />
                                    </div>
                                })
                            }


                            <Button onClick={this.addMore} icon="pi pi-plus" className="ma-addmore ma-pointer p-button-rounded p-button-outlined" />
                        </div>
                    </>}
                {
                    currentStep == 2 && <>
                        <div className="p-grid ma-w100p" >
                            <div className="p-col-12 p-md-7">
                                <p className='ma-h-title'>Assign Module Access</p>
                                <div className='modules-list'>
                                    {
                                        this.state.allModules && this.state.allModules.map((m, ind) => {
                                            return <div key={m.id} className='p-grid ma-m10 module-item'>
                                                <Checkbox inputId={`chp${ind}`}
                                                    checked={m.isSelected}
                                                    value={m.id}
                                                    onChange={this.handleCheckBoxChange(m.id)}
                                                ></Checkbox>
                                                <label htmlFor={`chp${ind}`} className="p-checkbox-label p-name">{m.groupName}</label></div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    currentStep == 3 && <>
                        <div className="p-grid ma-w100p" >

                            <div className="p-col-12 p-md-5">
                                <p className='ma-h-title'>Create Super Admin</p>

                                <div className="p-grid">

                                    <div className="p-col-12 p-md-6">
                                        <p className='ma-label-s2'>Super Admin UserName<span className='ma-required'>*</span></p>
                                        <InputTextB info={FIELDS_INFO.ORG_ADMIN_USER} id="cu4">
                                            <InputText value={user.username}
                                                disabled={this.props.editOrganization}

                                                onChange={(e) => {
                                                    onTextChange(e.target.value, 'username', this, adminUserFormFields, user, adminUserFormValidations, 'user', 'adminUserFormValidations')
                                                }} className='ma-w100p p-inputtext-style1' />
                                        </InputTextB>
                                        {adminUserFormValidations && !adminUserFormValidations.fields['username'].isValid && <p className="p-error">{adminUserFormValidations.fields['username'].errorMsg}</p>}
                                    </div>
                                </div>
                                {!this.props.editOrganization && <>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <p className='ma-label-s2'>Password<span className='ma-required'>*</span></p>
                                            <InputTextB info={FIELDS_INFO.ORG_ADMIN_PASSWORD} id="cu6">
                                                {/* <PasswordText
                                            valuee={user.password}
                                            onChangee={(e) => {
                                                onTextChange(e.target.value, 'password', this, adminUserFormFields, user, adminUserFormValidations, 'user')

                                            }}
                                            classNames='ma-w100p'
                                        /> */}
                                                <Password
                                                className='ma-w100p'
                                                    autocomplete="new-password"
                                                    disabled={this.props.editOrganization}
                                                    toggleMask
                                                    // className='ma-w100p p-inputtext-style1'
                                                    value={user.password} onChange={(e) => onTextChange(e.target.value, 'password', this, adminUserFormFields, user, adminUserFormValidations, 'user', 'adminUserFormValidations')}
                                                />

                                            </InputTextB>
                                            {adminUserFormValidations && !adminUserFormValidations.fields['password'].isValid && <p className="p-error">{adminUserFormValidations.fields['password'].errorMsg}</p>}
                                        </div>
                                    </div>


                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <p className='ma-label-s2'>Confirm Password<span className='ma-required'>*</span></p>
                                            <InputTextB info={FIELDS_INFO.ORG_ADMIN_CONFIRM_PASSWORD} id="cu7">
                                                {/* <PasswordText value={user.confirmPassword} onChange={(e) => {
                                            onTextChange(e.target.value, 'confirmPassword', this, adminUserFormFields, user, adminUserFormValidations, 'user')
                                        }} className=' ma-w100p' /> */}

                                                <Password
                                                className='ma-w100p'
                                                    autocomplete="new-password"
                                                    disabled={this.props.editOrganization}
                                                    toggleMask
                                                    //  className='ma-w100p p-inputtext-style1'
                                                    value={user.confirmPassword} onChange={(e) => onTextChange(e.target.value, 'confirmPassword', this, adminUserFormFields, user, adminUserFormValidations, 'user', 'adminUserFormValidations')}
                                                />
                                            </InputTextB>
                                            {adminUserFormValidations && !adminUserFormValidations.fields['confirmPassword'].isValid && <p className="p-error">{adminUserFormValidations.fields['confirmPassword'].errorMsg}</p>}
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </>
                }

            </div>
            <Toolbar className="ma-toolbar" right={() => { return (<><Button label={`${this.state.currentStep == 0 ? 'Cancel' : 'Previous'}`} className='p-button-outlined' onClick={this.onBackClick} /> <Button label={this.state.currentStep == 3 ? `Submit` : 'Save & Proceed'} className='ma-m-lr10' onClick={this.onSubmitClick} /></>) }}></Toolbar>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            <Dialog
                visible={this.state.isShowCreateSuccess}
                // style={{ width: '80%' }}
                blockScroll={true}
                // modal
                footer={() => {
                    return (<div className='ma-mt20'>
                        <Button label="Done" onClick={() => {
                            this.setState({
                                isShowCreateSuccess: false
                            }, () => {
                                this.props.onCreateOrEditSuccess()
                            })

                        }} />
                        {/* <Button label={`Send Notification`} className='ma-m-lr10' onClick={() => { this.setState({ isShowCreateSuccess: false }) }} /> */}

                    </div>)
                }}
                draggable={false}
                // footer={this.footer}
                closeOnEscape={true}
                dismissableMask={true}
                // visible={true}
                closable={false}
                onHide={() => {
                    this.setState({
                        isShowCreateSuccess: false
                    }, () => {
                        this.props.onCreateOrEditSuccess()
                    })
                }}>
                <div className='success-dialog'>
                    <img src="./images/success-icon.PNG" className='img-center' alt="success" />
                    <p className='success-title'>Success!</p>
                    <p className='success-msg'>
                        Organization {this.props.editOrganization ? 'Updated' : 'Created'} Successfully.
                    </p>
                </div>
            </Dialog>
        </>

        )
    }
}



const mapStateToProps = (state) => ({
    permissionsMasterList: selectPermissions(state, true),

});


const mapDispatchToProps = (dispatch) => ({
    getPermissions: () => dispatch(getPermissionList()),
});



export default connect(mapStateToProps, mapDispatchToProps)((withRouter(CreateOrganization)));


