import React, { Component } from 'react'
import Service from '../../services';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import SvgViewer from './../customComponents/svgViewer';
import MathJax from 'react-mathjax-preview';
import { InputSwitch } from 'primereact/inputswitch';
import moment from 'moment';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import Authentication from './../session';
import { Checkbox } from 'primereact/checkbox';
import { userAssignedBoards } from '../../store/selectors/userAssignedBoards';
import { withRouter } from 'react-router-dom';
import { getFormFields, isFormValid, isFieldValid, onTextChange, isValidMobile, onNumberChange, onDropDownChange, onChoiceChange, onEmailChange, isValidEmail, isValidUrl, onChipsChange } from '../../utile/formHelper';
import { Difficulty, ExamTypes, QuestionSource, QuestionTargets, QuestionTypeKeys, QuestionTypes, QuestionTypesWithAQ, SkillTypes, Status } from './constants';
import { baseUrlAdmin, baseUrlForLongRunningTask } from '../../store/apiConstants';
import {
    getBoardsData,
    getImageUrl
} from './../../store/actions';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import Authorizer, { PERMISSIONS } from '../session/authorizer';
import { BasicLazyParams, warningDailogInit } from '../../utile';

import SingleQuestion from './common/singleQuestion';
import { MultiSelect } from 'primereact/multiselect';
import PreviewQuestion from './common/previewQuestion';
import AssignQuestionToTopic from './common/assignQuestionToTopic';
import './styles.scss'
import LoadingComponent from '../loadingComponent';
import QuestionInTable from './common/questionInTable';
import { EditIcon, PreviewIcon, WarningIcon } from '../svgIcons';
import _ from 'lodash';

class SimilarQChecker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questionTarget: null,
            boardId: null,
            classId: null,
            groupId: null,
            classes: [],
            boards: [],
            groups: [],
            subjects: [],
            chapters: [],
            topics: [],
            subjectId: null,
            chapterId: null,
            topicId: null,
            questionType: null,
            difficulty: null,
            skillType: null,
            status: null,
            isShowQuestionPreview: false,
            isSearchByQuestionId: false,
            similarQDetails: [],
            questions: [],
            similarQuestions: [],
            selectedQuestion: null,
            columns: [],
            showSearch: false,
            globalFilter: null,
            globalSearch: '',
            totalRecords: 0,
            lazyParams: BasicLazyParams,
            selectedQuestions: [],
            warningDialog: warningDailogInit,
            disableAssign: false,
            accuracyLevel: 90,
        }
        this.service = new Service();
    }



    componentDidMount() {
        if (this.props.questionId) {

        } else {



        }


        if (!this.props.boards || !this.props.boards.length) {
            this.props.getBoardsData();
        }

    }



    onQuestionTargetChange = (questionTarget) => {
        this.setState({
            questionTarget,
            questions: [],
            totalRecords: 0,
            selectedQuestions: []
        }, () => {
        })
    }



    onChangeBoard = (boardId) => {
        this.setState({
            classes: [], groups: [],
            classId: null, groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: [],
            questions: [],
            totalRecords: 0,
            selectedQuestions: []
        });
        const _classes = [];
        const selectedBoard = this.props.boards && this.props.boards.find((board) => board.boardId === boardId);

        if (selectedBoard && selectedBoard.classes && selectedBoard.classes.length > 0) {
            selectedBoard.classes.forEach((item) => _classes.push({ label: item.className, value: item.classId }));
        }
        this.setState({
            classes: _classes,
            boardId,
            boardName: selectedBoard.boardName,
            resourseOrigin: selectedBoard.isBase ? 'upload' : null

        });

    };

    onChangeClass = (classId) => {
        this.setState({

            groups: [], groupId: null,
            subjects: [], subjectId: null,
            chapters: [], chapterId: null,
            topics: [], topicId: null,
            content: null,
            filteredContentDetails: [],
            questions: [],
            totalRecords: 0,
            selectedQuestions: []
        });
        const boardId = this.state.boardId;
        const _groups = [];
        const selectedBoard =
            this.props.boards && this.props.boards.find((board) => board.boardId === boardId);
        const selectedClass = selectedBoard.classes.find((_class) => _class.classId === classId);
        if (selectedClass && selectedClass.groups && selectedClass.groups.length > 0) {
            selectedClass.groups.map((item) => _groups.push({ label: item.groupName, value: item.groupId }));
            this.setState({
                groups: _groups,
                classId,
                className: selectedClass.className,
                groupId: _groups[0].value,
                groupName: _groups[0].label,
            }, () => {
                this.getCurriculumData();
            });
        }
    };

    onChangeGroup = (groupId) => {
        this.setState(
            {
                groupId,

                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            },
            () => {
                this.getCurriculumData();
            }
        );
    };
    // grades selection --- end


    //subjects --- start


    getCurriculumData = () => {
        const { getCurriculum } = this.props;
        const { boardId, classId, groupId } = this.state;
        if (boardId && classId && groupId) {
            this.setState({
                isLoading: true
            })
            const url = `${baseUrlAdmin}/board/${boardId}/class/${classId}/group/${groupId}/active`;
            this.service.get(url, true).then((data) => {

                if (data && data.res && data.res.Item) {
                    this.setState({
                        curriculumInfo: data.res.Item,
                        subjects: data.res.Item.subjects,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            });
            //  getCurriculum(boardId, classId, groupId);
        } else {
            this.setState({
                isLoading: false
            })
            // this.snackBarOpen('select board, class and group id');
        }
    };




    onChangeSubject = (subjectId) => {
        let selectedSubject = this.state.subjects.filter((subject) => subject.subjectId == subjectId);

        this.setState((prevState) => {
            return {
                subjectId,
                subjectName: selectedSubject[0].subjectName,
                chapterId: null,
                topicId: null,
                chapters: selectedSubject[0].chapters,
                topics: [],
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            };
        });
    };

    onChangeChapter = (chapterId) => {
        let selectedChapter = this.state.chapters.filter((chapter) => chapter.chapterId == chapterId);
        this.setState(
            {
                topics: selectedChapter[0].topics,
                chapterId,
                chapterName: selectedChapter[0].chapterName,
                topicId: null,
                content: null,
                filteredContentDetails: [],
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            });
    };

    onChangeTopic = (topicId) => {
        let selectedTopic = this.state.topics.find(topic => topic.topicId == topicId);
        this.setState(
            {
                topicId,
                topicName: selectedTopic.topicName,
                questions: [],
                totalRecords: 0,
                selectedQuestions: []
            });
    };


    handleClearSearch = () => {

        this.setState(
            {
                boardId: null,
                classId: null,
                groupId: null,
                classes: [],
                boards: [],
                groups: [],
                subjects: [],
                chapters: [],
                topics: [],
                subjectId: null,
                chapterId: null,
                topicId: null,
                questionId: '',
                selectedQuestions: []

            }
        );
    };

    onFetch = () => {
        this.setState({
            globalSearch: '',
            lazyParams: BasicLazyParams,
            selectedQuestions: []
        }, this.onSearch);
    }

    onSearch = () => {
        const { questionTarget,
            boardId, classId,
            groupId, subjectId,
            chapterId, topicId,
            questionType, difficulty,
            skillType, status,
            lazyParams, globalSearch,
            isSearchByQuestionId,
            accuracyLevel
        } = this.state;

        let filters = { questionTarget, accuracyLevel };

        if (!questionTarget) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select question Target', life: 3000 });
            return
        }

        if (!boardId || !classId || !subjectId) {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please select Board, Class and Subject', life: 3000 });
            return
        }

        if (topicId) {
            filters = { ...filters, topicId: [topicId] }
        } else if (chapterId) {
            filters = { ...filters, chapterId: [chapterId] }
        } else if (subjectId) {
            filters = { ...filters, subjectId: [subjectId] }
        } else if (classId) {
            filters = { ...filters, classId: [classId] }
        } else if (boardId) {
            filters = { ...filters, boardId: [boardId] }
        }


        
        this.setState({
            isLoading: true,
            questions: [],
            similarQuestions: [],
            totalRecords: 0
        });

        this.service.post(`${baseUrlForLongRunningTask}/question/findDuplicate`, filters, true).then((data) => {
            if (data && data.status && data?.res?.data) {
                this.setState({
                    totalRecords: data.res.data.totalRecords,
                    similarQuestions: data.res.data.duplicateQuestions,
                    questions: data.res.data.questions,
                    isLoading: false,
                })
            } else {
                this.setState({
                    isLoading: false,

                });
            }


        }).catch(e => {
            this.setState({
                isLoading: false,

            });
            console.log(e);

        });


    }


    onSelectQuestions = (e) => {
        this.setState({ selectedQuestions: e.value })
    }


    isSelectedAllItems = (tableItems, selectedItems, uniqField) => {
        if (tableItems && tableItems.length) {
            let d = tableItems.every(a => selectedItems.find(b => a[uniqField] == b[uniqField]) ? true : false);

            return d;
        } else {
            return false;
        }
    }

    isSelected = (rowData, selectedItems, uniqField) => {
        return selectedItems.find(a => a[uniqField] == rowData[uniqField]) ? true : false;
    }

    selectOrdeselect = (type, selectedItems, items, isSelect, selectedItemsField, uniqField) => {
        let selectedIts = _.cloneDeep(selectedItems);
        if (type == 'list') {
            if (isSelect) {
                items.forEach(i => {
                    let data = selectedIts.find(a => a[uniqField] == i[uniqField]);
                    if (!data) {
                        selectedIts.push(i);
                    }
                })
            } else {
                items.forEach(i => {
                    selectedIts = selectedIts.filter(a => a[uniqField] != i[uniqField]);
                })
            }
        } else {
            if (isSelect) {
                selectedIts.push(items)
            } else {
                selectedIts = selectedIts.filter((i) => i[uniqField] != items[uniqField]);
            }
        }

        this.setState({
            [selectedItemsField]: selectedIts
        })





    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
                // selectedQuestions :[]
            }
        }, this.onSearch);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }



    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.onSearch);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.onSearch);
        //  this.setState({ lazyParams: event }, this.getOrganizations);
    }

    onColumnClick = (column) => {
        let columns = this.state.columns.map(c => {
            if (column.id == c.id) {
                c.isVisible = !c.isVisible;
            }
            return c;
        });
        this.setState({
            columns
        });
    }


    isColumnVisible = (id) => {
        return this.state.columns.filter(c => c.id == id)[0].isVisible;
    }


    exportCSV = () => {
        this.dt.exportCSV();
    }


    optionsRender = (options) => {
        return <div>
            {options && options.length ? options.map(option => <div key={option.id}> <SingleQuestion optionN={option.id} mathJaxValue={option.value} attachment={option.imageUrl} /> </div>) : <></>
            }
        </div>

    }
    showSimilars = (rowData) => {
        let similarQDetails = []
        

        rowData.result.forEach(q => {

            let key = Object.keys(q)[0];

            let abc = this.state.questions.find(a => a.questionId == key)
            
            similarQDetails.push(abc);
        });

        console.log(similarQDetails, 'similarQDetails');

        this.setState({
            selectedQuestion: this.state.questions.find(a => a.questionId == rowData.questionId),
            isShowSimilarsQuestions: true,
            similarQDetails
        })


    }


    replaceQuestions = (replaceQestion) => {

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `It will replace the duplicate questions with the selected, it can't be undone once processed.`
            },
            replaceQuestionId: replaceQestion.questionId
        });



    }


    onConfirmChange = () => {
        
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })


        let obj = {
            questionId: this.state.replaceQuestionId,
            replaceQuestionIds: []
        };
        this.state.similarQuestions.find(q => q.questionId == obj.questionId).result.forEach(q => {
            let key = Object.keys(q)[0];

            obj.replaceQuestionIds.push(key);
        });

        
        const url = `${baseUrlForLongRunningTask}/question/updateduplicateQuestions`;
        this.service.post(url, obj, true).then((res) => {

            

            obj.replaceQuestionIds.push(obj.questionId)

            if (res && res.res && res.res.success) {
                let similarQuestions = this.state.similarQuestions.filter(q => !obj.replaceQuestionIds.includes(q.questionId));
                this.setState({
                    isLoading: false,
                    similarQuestions,
                    isShowSimilarsQuestions: false,
                    similarQDetails: []

                })
                this.toast.show({ severity: 'success', summary: 'Question Removed', detail: res.res.message, life: 3000 });

            } else {

                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });

    }


    // renderQuest  = (question) => {
    //     console.log(question)
    //     switch (question.questionType) {
    //         case QuestionTypeKeys.SCCT:
    //         case QuestionTypeKeys.MCCT:
    //             return <>
    //                 <SingleQuestion mathJaxValue={question.question} attachment={question.attachmentUrl} />
    //                 {this.optionsRender(question.options)}
    //             </>

    //         default: return <></>

    //     }
    // }


    questionsAvailableR = () => {
        return <>
            <p className='avail-q'> Available Questions:  <span>{this.state.totalRecords}</span> </p>
        </>
    }
    showAssignPopup = () => {
        if (this.state.selectedQuestions && this.state.selectedQuestions.length) {
            this.setState({ showAssignPopup: true })
        } else {
            this.toast.show({ severity: 'error', summary: 'Invalid', detail: 'Please Select atleast one question to assign.', life: 3000 });
        }
    }

    onSucessfullyAssigned = () => {
        this.setState({
            showAssignPopup: false
        });
        this.toast.show({ severity: 'success', summary: 'Assigned', detail: 'Successfully Assigned to topics.', life: 3000 });
    }


    render() {
        const { questionToMove } = this.state;
        //  console.log('selectedQuestions', this.state.selectedQuestions)
        const header = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Duplicate Questions List</h2>
                    {this.state.showSearch && <InputText placeholder="Search" value={this.state.globalSearch} className="ma-tbl-search p-inputtext-md" onChange={this.onGlobalSearch} />}

                </div>


            </div>
        );

        const originalQHeader = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Original Question</h2>

                </div>

            </div>
        );

        const similarQHeader = (
            <div className="table-header ma-tbl-header">
                <div className="ma-tbl-left">
                    <h2 className='ma-table-title'>Similar Questions</h2>

                </div>

            </div>
        );
        return (
            <div>

                <div>
                    <div className='ma-mt10'>
                        <Dropdown value={this.state.questionTarget}
                            className='ma-w200 ma-mt10'
                            options={[...QuestionTargets, { id: 'General', value: 'General' }]}
                            optionLabel="value"
                            optionValue="id"
                            onChange={(e) => this.onQuestionTargetChange(e.value)}
                            placeholder="Question Target" />
                        <span className='ma-required-mark '>*</span>
                        <Dropdown value={this.state.boardId}
                            className='ma-w200 ma-mt10'
                            options={this.props.boards}
                            optionLabel="boardName"
                            optionValue="boardId"
                            onChange={(e) => this.onChangeBoard(e.value)}
                            placeholder="Select Board" />
                        <span className='ma-required-mark ma-mr20  '>*</span>
                        {this.state.boardId && <> <Dropdown value={this.state.classId}
                            options={this.state.classes}
                            className='ma-w200 ma-mt10'
                            onChange={(e) => this.onChangeClass(e.value)} placeholder="Select Class" />
                            <span className='ma-required-mark ma-mr20  '>*</span>
                        </>}
                        {this.state.groupId && <><Dropdown value={this.state.subjectId}
                            options={this.state.subjects}
                            optionLabel='subjectName'
                            optionValue='subjectId'
                            className='ma-w200 ma-mt10'
                            onChange={(e) => this.onChangeSubject(e.value)} placeholder="Select Subject" />
                            <span className='ma-required-mark ma-mr20  '>*</span>
                        </>
                        }

                        {this.state.subjectId && <Dropdown value={this.state.chapterId}
                            options={this.state.chapters}
                            optionLabel='chapterName'
                            optionValue='chapterId'
                            className='ma-mr20 ma-w200 ma-mt10'
                            onChange={(e) => this.onChangeChapter(e.value)} placeholder="Select Chapter" />}

                        {this.state.chapterId && <Dropdown value={this.state.topicId}
                            options={this.state.topics}
                            optionLabel='topicName'
                            optionValue='topicId'
                            className='ma-mr20 ma-w200 ma-mt10'
                            onChange={(e) => this.onChangeTopic(e.value)} placeholder="Select Topic" />}

                    </div>
                    <Dropdown value={this.state.accuracyLevel}
                        className='ma-w200 ma-mt10'
                        options={[90, 80, 70]}
                        onChange={(e) => this.setState({ accuracyLevel: e.value })}
                        placeholder="Select Board" />
                    <Toolbar className="ma-toolbard ma-mt20" left={() => {
                        return (
                            <React.Fragment>
                                <Button label='Submit' onClick={this.onFetch} />
                            </React.Fragment>
                        )
                    }}></Toolbar>
                </div>
                <div className="card datatable-crud-demo fetch-q-tabled ma-mt20">
                    <DataTable ref={(el) => this.dt = el}
                        scrollable scrollHeight="500px"
                        responsiveLayout="scroll"
                        dataKey="_id"
                        value={this.state.similarQuestions}
                        filters={this.state.lazyParams.filters}
                        className="ma-table-d"
                        tableClassName='ma-table-tbl'

                        paginatorLeft={this.questionsAvailableR}
                        paginator
                        rows={10}


                        totalRecords={this.state.totalRecords}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}

                        // selection={this.state.selectedQuestions}
                        // onSelectionChange={this.onSelectQuestions}

                        //paginatorTemplate={paginatorTemplate}
                        //paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        // globalFilter={this.state.globalFilter}
                        header={header}>
                        <Column headerClassName='question' header="Sl No" body={(rowData, { rowIndex }) => {
                            return (<>
                                {rowIndex + 1}
                            </>)
                        }} />
                        <Column headerClassName='question-id' field="questionId" header="Question ID" />
                        <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                            return (<>

                                <QuestionInTable question={this.state.questions.find(a => a.questionId == rowData.questionId)} />
                            </>)
                        }} />
                        <Column headerClassName='difficulty' field="result" header="Similar Count"
                            body={(rowData) => {
                                return (<>
                                    <span className='ma-pointer' style={{ fontWeight: 'bold', fontSize: '16px', color: 'var(--primary-color' }}
                                    > {rowData.result && rowData.result.length ? rowData.result.length : '-'}</span>
                                </>)
                            }}
                        />
                        <Column header="Actions" body={(rowData) => {
                            return (
                                <Authorizer permId={PERMISSIONS.QUESTION_VIEW_DUPLICATES} >
                                    <Button label="View Duplicates" className='p-button-outlined' onClick={() => { this.showSimilars(rowData) }} />
                                </Authorizer>
                            );
                        }}></Column>
                    </DataTable>
                    <Toast ref={(el) => this.toast = el} position="bottom-right" />
                </div>


                {
                    this.state.isShowQuestionPreview && <PreviewQuestion
                        question={this.state.questionToPreview}
                        onHide={() => {
                            this.setState({
                                isShowQuestionPreview: false
                            })
                        }}
                    />
                }


                {
                    this.state.showAssignPopup && <AssignQuestionToTopic
                        selectedQuestions={this.state.selectedQuestions}
                        onCloseDialog={() => { this.setState({ showAssignPopup: false }) }}
                        onSucessfullyAssigned={this.onSucessfullyAssigned} />
                }

                {
                    (this.state.isLoading || this.props.isLoading) && <LoadingComponent />
                }


                <Dialog
                    visible={this.state.isShowSimilarsQuestions}
                    style={{ width: '90%', minHeight: '90vh' }}
                    header={"Similar Questions"}
                    modal
                    draggable={false}
                    // footer={this.footer}
                    blockScroll={true}
                    closeOnEscape={false}
                    dismissableMask={false}
                    closable={true}
                    onHide={() => {
                        this.setState({ isShowSimilarsQuestions: false, similarQDetails: [] })
                    }}>
                    <div>
                        <div className="card datatable-crud-demo fetch-q-tabled ma-mt20 questions-d">
                            <DataTable ref={(el) => this.dt = el}
                                scrollable scrollHeight="500px"
                                responsiveLayout="scroll"
                                dataKey="_id"
                                value={[this.state.selectedQuestion]}
                                filters={this.state.lazyParams.filters}
                                className="ma-table-d"
                                tableClassName='ma-table-tbl'
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                header={originalQHeader}>
                                <Column headerClassName='question' header="Sl No" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        {rowIndex + 1}
                                    </>)
                                }} />
                                <Column headerClassName='question-id' field="questionId" header="Question ID" />
                                <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                                    return (<>
                                        <QuestionInTable question={rowData} />
                                    </>)
                                }} />
                                <Column headerClassName='question' header="Sl No" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        <Authorizer permId={PERMISSIONS.QUESTION_REPLACE_DUPLICATES} >
                                            <Button label='Replcae duplicates' onClick={() => { this.replaceQuestions(rowData) }} />
                                        </Authorizer>
                                    </>)
                                }} />
                            </DataTable>
                        </div>

                        <div className="card datatable-crud-demo fetch-q-tabled ma-mt20 questions-d">
                            <DataTable ref={(el) => this.dt = el}
                                scrollable scrollHeight="500px"
                                responsiveLayout="scroll"
                                dataKey="_id"
                                value={this.state.similarQDetails}
                                filters={this.state.lazyParams.filters}
                                className="ma-table-d"
                                tableClassName='ma-table-tbl'
                                paginatorLeft={this.questionsAvailableR}
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                header={similarQHeader}>
                                <Column headerClassName='question' header="Sl No" body={(rowData, { rowIndex }) => {
                                    return (<>
                                        {rowIndex + 1}
                                    </>)
                                }} />
                                <Column headerClassName='question-id' field="questionId" header="Question ID" />
                                <Column headerClassName='question' header="Question and solution" body={(rowData) => {
                                    return (<>
                                        <QuestionInTable question={rowData} />
                                    </>)
                                }} />
                            </DataTable>
                        </div>


                    </div>
                </Dialog>

                <Dialog
                    header={<></>}
                    draggable={false}
                    closeOnEscape={true}
                    className='ma-alert-box'
                    blockScroll={true}
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                    footer={() => {
                        return (<div>
                            <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, replaceQuestionId: null }) }} />
                            <Button label="Yes" className='ma-m-lr10' onClick={this.onConfirmChange} />

                        </div>)
                    }}
                    onHide={() => this.setState({ warningDialog: warningDailogInit, replaceQuestionId: null })}>
                    <div>
                        <span className="warning-icon" >
                            <WarningIcon width={"100%"} height={"100%"} />
                        </span>
                    </div>
                    <p className='ma-alert-head'>{this.state.warningDialog.headerMsg}</p>
                    <p className='ma-alert-msg'>{this.state.warningDialog.message}</p>
                </Dialog>

            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    boards: userAssignedBoards(state, 'activeBoards'),
    isLoading: state.boardsData.loading,
    getAllImagesUrlInfo: (state.getImageUrlInfo && state.getImageUrlInfo.allImageUrls) ? state.getImageUrlInfo.allImageUrls : {},

});

export default connect(mapStateToProps, {
    getBoardsData,
    getImageUrl

})(Authentication(withRouter(SimilarQChecker)));