import React, { Component } from 'react'
import Navbar from './../navbar';
import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import './styles.scss';
import { connect } from 'react-redux';
import Authentication from './../session';

import Authorizer from '../session/authorizer';
import axios from 'axios';
import LoadingComponent from '../loadingComponent';
import { getServerTime } from '../../utile';
import UsersStats from './widgets/usersStats';
import ResourceUsageReport from './widgets/resourseUsageReport';
import TrendingChapters from './widgets/trendingChapters';
import AskADoubtStats from './widgets/askADoubtStats';
import DiscussionBoardStats from './widgets/discussionboardStats';

import Notifications from './widgets/notifications'
import ChapterStrength from './widgets/chapterStrength';
import BrachWiseUsageReport from './widgets/BrachWiseUsageReport';
import DeviceWiseAtiveReport from './widgets/DeviceWiseAtiveReport';
import QuestionBankReport from './widgets/QuestionBankReport';
import ResourceWiseContent from './widgets/ResourceWiseContent';
import LanguageWiseResources from './widgets/LanguageWiseResources';
import TicketManagement from './widgets/ticketManagement';
import { baseUrlAdmin } from '../../store/apiConstants';
import Service from '../../services';
import { cloneDeep } from 'lodash';


class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            questionBankReport : [],
            resourceWiseContent : [],
            langWiseResource : [],
        }
        this.service =  new Service()
    }



    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <h2 className='ma-tab-title'>DashBoard</h2>
            </React.Fragment>
        )
    }

    handleDownload = (url, filename) => {

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    getServerTime().getTime().toString() + '.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }


    genAndDownloadPaper = () => {
        this.setState({
            isLoading: true
        });
        let url = 'http://localhost:3000/v1/data/examination/d0c5e162-d23d-468c-9b60-958266f8f46d/download-paper';
        axios.get(url, {})
            .then((res) => {
                if (res && res.data && res.data.data && res.data.data.paperLink) {
                    this.handleDownload('https://meluhaofflinecontent.s3.ap-south-1.amazonaws.com/' + res.data.data.paperLink)
                    this.setState({
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch(e => {

                this.setState({
                    isLoading: false
                });
            });

    }

    componentDidMount(){
        this.getWidgetsData()
    }

    getWidgetsData = () => {
        this.setState({isLoading: true})
        const url = `${baseUrlAdmin}/notification-manager/dashboard-stats`;
        this.service.get(url, true).then(res => {
            if (res && res.status) {
                let array1 = cloneDeep(res?.res?.languages) 
                let array2 = []
                array1.forEach(item => {
                    let existingItem = array2.find(i => i.contentLanguage === item.contentLanguage);
                    if (existingItem) {
                        existingItem[item.contentResourceType] = item.count;
                    } else {
                        let newItem = {
                            contentLanguage: item.contentLanguage,
                            [item.contentResourceType]: item.count
                        };
                        array2.push(newItem);
                    }
                });
                this.setState({
                    questionBankReport : res?.res?.questions,
                    resourceWiseContent : res?.res?.resource,
                    langWiseResource : array2,
                    isLoading: false
                })
            } else {
                this.setState({isLoading: false});
            }
        }).catch(e => {
            this.setState({isLoading: false});
        })
    }
    render() {
        return (
            <>
                <Navbar>
                    <div className='ma-toolbar'>
                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    <div className="ma-main">

                        <div className='p-grid '>
                            <div className='p-col-12 p-md-9 '>
                                <UsersStats />
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <ResourceUsageReport />
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <TrendingChapters />
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <AskADoubtStats />
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <DiscussionBoardStats />
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <BrachWiseUsageReport />
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <DeviceWiseAtiveReport />
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <QuestionBankReport questionBankReport={this.state.questionBankReport}/>
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <ResourceWiseContent resourceWiseContent= {this.state.resourceWiseContent}/>
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <LanguageWiseResources langWiseResource = {this.state.langWiseResource}/>
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <TicketManagement />
                                    </div>
                                </div>
                                {/* <div className='p-grid'>
                                    <div className='p-col-12 p-md-6'>
                                        <ChapterStrength />
                                    </div>
                                   
                                </div> */}
                            </div>
                            <div className='p-col-12 p-md-3'>
                                <Notifications />

                            </div>

                        </div>

                    </div>
                    {/* <button onClick={() => this.genAndDownloadPaper()}>Download</button> */}
                    {/* <div className="p-grid">

                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Organization</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Curriculum</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Content Master</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-3">
                            <div className="dashboard-card">
                                <p className="card-title">Package Master</p>
                                <hr className="ma-hr" />
                                <div className="p-grid">
                                    <div className="p-col-8 p-md-8 p-lg-8">
                                        <p className="card-value">550+</p>
                                        <p className="card-time">Update: 1 day ago</p>
                                    </div>
                                    <div className="p-col-4 p-md-4 p-lg-4">
                                        <img src="./images/faculty.png" style={{ height: '34px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> */}
                    {
                        this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }

                </Navbar>
            </>
        )
    }
}



export default connect(null, {})(Authentication(DashBoard));